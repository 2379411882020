import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { verifyCode } from "../../../Storage/Redux/loginSlice";
import { getUserInfo } from "../../../Storage/Redux/projectSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import "../User/Login.css";

const Verify = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [codeData, setCodeData] = useState({ code: "" });
  const [errorMsg, setErrorMsg] = useState("");
  const userMail = localStorage.getItem("userEmail");
  const [errors, setErrors] = useState({});
  const [showLoader, setShowLoader] = useState(false);

  const cursorPositionRef = useRef(null);
  const schema = Yup.object().shape({
    code: Yup.number()
      .typeError("Enter a Valid Code")
      .required("Code is required"),
  });

  useEffect(() => {
    const userMail = localStorage.getItem("userEmail");
    if (userMail === null || undefined) {
      navigate("/");
    }
  }, []);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    const cursorPosition = e.target.selectionStart;
    cursorPositionRef.current = cursorPosition;
    try {
      await schema.validateAt(name, { [name]: value });
      setErrors({ ...errors, [name]: "" });
    } catch (error) {
      setErrors({ ...errors, [name]: error.message });
    }
    setCodeData({
      ...codeData,
      [name]: value,
      userName: userMail !== "" ? userMail : "",
    });
    setTimeout(() => {
      e.target.setSelectionRange(
        cursorPositionRef.current,
        cursorPositionRef.current
      );
    }, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await schema.validate(codeData, { abortEarly: false });
      CallApi();
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  const CallApi = () => {
    setShowLoader(true);
    dispatch(verifyCode(codeData))
      .unwrap()
      .then((responseData) => {
        localStorage.setItem(
          "userTokenInfo",
          JSON.stringify(responseData?.data?.response)
        );

        dispatch(getUserInfo(responseData?.data?.response?.userId))
          .unwrap()
          .then((data) => {
            localStorage.setItem("UserInfo", JSON.stringify(data));
            setShowLoader(false);
            const userData = localStorage.getItem("UserInfo");
            const userInfo = JSON.parse(userData);
            if (userInfo !== undefined || null) {
              const NavPage =
                data?.userType === "admin" ? "/dashboard" : "/user-home";
              navigate(NavPage);
            }
            window.location.reload();
          });
      })
      .catch((e) => {
        setShowLoader(false);
        setErrorMsg("Invalid Code");
        console.log(e, "Unable to verify the code");
      });
  };

  return (
    <div className="login-container-new">
      <div className="login-screen-gradient">
        <img
          src={require("../../../Assets/Images/new-gradient-background.png")}
          className="back-grad"
        />
      </div>
      <div className="new-login-main">
        <div className="login-form">
          <div className="logo-div">
            <img
              src={require("../../../Assets/Images/purple_logo_new.png")}
              alt="logo"
            />
          </div>
          <h2 style={{ marginBottom: "0.8rem" }}>OTP Verification</h2>
          <p style={{ marginBottom: "50px" }}>
          Please check your mail. We have sent a code
          </p>
          <form onSubmit={handleSubmit}>
            <div style={{ marginBottom: "80px" }}>
              <label htmlFor="code" className="new-login-label">
                <span className="red-star">
                  <sup>*</sup>
                </span>
                Enter the code
              </label>
              <input
                type="text"
                id="code"
                name="code"
                value={codeData?.code || ""}
                onChange={handleInputChange}
                autoComplete="off"
                placeholder="Enter your code"
                className="input-new-login"
                maxLength="6"
              />
              {errors.code && <div className="error">{errors.code}</div>}
            </div>

            {errorMsg !== "" ? (
              <div className="failure-div">
                <div className="failure-text">{errorMsg}</div>
              </div>
            ) : (
              ""
            )}
            {showLoader ? (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <FontAwesomeIcon
                  icon={faSpinner}
                  color="#5b72da"
                  spin
                  size="2x"
                />
              </div>
            ) : (
              ""
            )}
            <button type="submit" className="login-button">
              Verify
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Verify;
