import React, { useState,useRef } from "react";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../../Storage/Redux/loginSlice";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "../User/Login.css";

const Forgot = () => {
  const dispatch = useDispatch();
  const cursorPositionRef = useRef(null);
  const [forgotData, setForgotData] = useState({ username: "" });
 const [submitted, setSubmitted] = useState("");
  const [errors, setErrors] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const schema = Yup.object().shape({
    username: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    const cursorPosition = e.target.selectionStart;
    cursorPositionRef.current = cursorPosition;
    try {
      await schema.validateAt(name, { [name]: value });
      setErrors({ ...errors, [name]: "" });
    } catch (error) {
      setErrors({ ...errors, [name]: error.message });
    }
    setForgotData({ ...forgotData, [name]: value });
    setTimeout(() => {
      e.target.setSelectionRange(cursorPositionRef.current, cursorPositionRef.current);
    }, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await schema.validate(forgotData, { abortEarly: false });
      CallApiData();
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  const CallApiData = () => {
    setShowLoader(true);
    let { username } = forgotData;

    dispatch(forgotPassword(username))
      .unwrap()
      .then((data) => {
        setShowLoader(false);
        console.log(data);
        setSubmitted("Reset password link sent to email successfully");
      })
      .catch((e) => {
        setSubmitted(
          "Password reset instructions have been emailed. If not received, verify the email or try again."
        );
        setShowLoader(false);
        console.log(e, "error");
      });
  };

  return (
    <div className="login-container-new">
      <div className="login-screen-gradient">
        <img
          src={require("../../../Assets/Images/new-gradient-background.png")}
          className="back-grad"
        />
      </div>
      <div className="new-login-main">
        <div className="login-form">
        <div className="logo-div">
            <img src={require('../../../Assets/Images/purple_logo_new.png')} alt="logo" />
          </div>
         
          <h2 style={{ marginBottom: "0.8rem" }}>Forgot Password</h2>
          <p className="below-heading-text">Enter the email address associated with your account.<br/>We’ll send you a link to reset the password. 
          </p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="new_password" className="new-login-label">
                <span className="red-star">
                  <sup>*</sup>
                </span>
                Email
              </label>
              <div className="password-input">
                <input
                  className="input-new-login"
                  type= "text"
                  id="new_password"
                  name="username"
                  value={forgotData?.email}
                  onChange={handleInputChange}
                  autoComplete="off"
                placeholder="Enter your email"
                />
              </div>
              <div>
              {errors.username && (
                    <span style={{ color: "red", "font-size": "14px", paddingLeft:"5px"}}>
                      {errors.username}
                    </span>
                  )}
              </div>
            </div>
          
            {showLoader ? (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <FontAwesomeIcon
                  icon={faSpinner}
                  color="#5b72da"
                  spin
                  size="2x"
                />
              </div>
            ) : (
              ""
            )}
              {submitted !== "" ? (
                <div style={{ color: "#67ab67", textAlign:"center" }}>{submitted}</div>
              ) : (
                <div style={{ color: "#cb4747" }}>{submitted}</div>
              )}
           
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "15px",
                color: "green",
                marginBottom:"0px"
              }}
            >
            </p>
            <button type="submit" className="login-button">
              Send Link
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
