import React, { useRef } from "react";
import "./NewZenithProject.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import OneminGIF from "../../../Assets/Images/30sec.gif";
import PDFWarning from "../../../Assets/Images/pdfwarning.png";
import Cross from "../../../Assets/Images/cross.png";
import UploadIcon from "../../../Assets/Images/uploadIcon.png";

import {
  getCounty,
  createProject,
  getZoning,
  getCountyProduct,
  getProductReviewType
} from "../../../Storage/Redux/projectSlice";
import { saveProjectFile } from "../../../Storage/Redux/fileSlice";
const NewZenithProject = ({setCollapsed}) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const cursorPositionRef = useRef(null);
  const userData = localStorage.getItem("UserInfo");
  const userInfo = JSON.parse(userData);
  const [report, setReport] = useState({
    projectName: "",
    Zoning: "",
    siteAddress: "",
    pdfname: "",
    typereview: [],
  });
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [cityName, setCityName] = useState("");
  const [countyId, setCountyId] = useState("");
  const [lebelText, setlebelText] = useState("Address");
  const [reviewType, setReviewType] = useState([]);
  const [zoningdistrict, setZoningDistrict] = useState([]);
  const [countyData, setcountyData] = useState([]);
  const [CountyName, setCountyName] = useState("");
  const [site, setSite] = useState("address");
  const [reviewData, setReviewData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [PDFWarningShow, setPDFWarningShow] = useState(false);
  const [completenessCheckEnabled, setCompletenessCheckEnabled] = useState(false);
  const [overrideCompletenessCheck, setOverrideCompletenessCheck] = useState(false);
  const popRef = useRef(null);
  const schema = Yup.object().shape({
    projectName: Yup.string().required("Project Name is required"),

    siteAddress: Yup.string().required("Site Address is required"),
    zoningdistrict: Yup.string().required("Zoning Name is required"),

    pdfname: Yup.mixed().test("is-pdf", "Upload the PDF file", (value) => {
    if (!value) return false;
    const fileName = value.name.toLowerCase();
    return (
      fileName.endsWith(".pdf") ||
      fileName.endsWith(".dxf") ||
      fileName.endsWith(".dwg")
    );
  })
  .test("fileSize", "For Trial, the file must be less than 40 MB", (value) => {
    if (!value) return true;
    const fileSizeInMB = value.size / (1024 * 1024);
    return fileSizeInMB <= 40;
  }),
    typereview: Yup.array()
      .min(1, "At least one review type must be selected")
      .required("At least one review type must be selected"),
  });

  useEffect(() => {
    const userData = localStorage.getItem("UserInfo");
    const userInfo = JSON.parse(userData);

    if (userInfo === null || undefined) {
      navigate("/");
    }
    setCityName("");
    setCountyId("");
    dispatch(getCounty(userInfo?.clientId))
      .unwrap()
      .then(async (data) => {
        if (data?.statusCode == "200" || data?.statusCode == "OK") {
          setcountyData(data?.resultObj);
          const countyId = data?.resultObj[0]?.id;
          setCountyId(countyId);
          handleSelection(countyId);

          const { name, value } = {};
          try {
            await schema.validateAt(name, { [name]: value });
            setErrors({ ...errors, [name]: "" });
          } catch (error) {
            setErrors({ ...errors, [name]: error.message });
          }
          setReport({ ...report, [name]: value });
          setCityName(data?.resultObj[0]?.cityName);
          const CountyName=data?.resultObj[0]?.countyName;
          setCountyName(CountyName);
          
          
            dispatch(getZoning(countyId))
            .unwrap()
            .then((response) => {
              setZoningDistrict(response);
            });

          dispatch(
            getProductReviewType(
              {productId: 1, countyId: countyId }
            )
          )
            .unwrap()
            .then((data) => {
              if (data?.statusCode == "200" || data?.statusCode == "OK") {
                setReviewType(data?.resultObj);
              }
            })
            .catch((e) => {
              console.log(e, "error");
            });
        }
      })
      .catch((e) => {
        console.log(e, "error");
      });
  }, []);

  const handleSelection = async (countyId) => {
    try {
      const response = await dispatch(getCountyProduct({ countyId: countyId, productId: 1 })).unwrap();
  
      const { resultObj } = response;
      if (resultObj.length > 0) {
        if ('completenessCheckEnabled' in resultObj[0] && resultObj[0].completenessCheckEnabled) {
          setCompletenessCheckEnabled(true);
        } else {
          setCompletenessCheckEnabled(false);
        }
      } else {
        setCompletenessCheckEnabled(false);
      }
      const reviewResponse = await dispatch(getProductReviewType({productId: 1, countyId: countyId })).unwrap()
    
      if (reviewResponse?.statusCode === "200" || reviewResponse?.statusCode === "OK") {
        setReviewType(reviewResponse.resultObj);
      }

      // const zoningValue = await dispatch(getZoning(userInfo?.clientId)).unwrap();

      // if (zoningValue?.statusCode === "200" || reviewResponse?.statusCode === "OK") {
      //   console.log(zoningValue.resultObj)
      // }

      const zoningResponse = await dispatch(getZoning(countyId)).unwrap();
      setZoningDistrict(zoningResponse);

    } catch (error) {
      console.error("Error fetching county product:", error);
      setCompletenessCheckEnabled(false);
    }
  };
  
  useEffect(() => {
    handleSelection();
  }, []);

  const HandlePDFWarning = () => {
    setPDFWarningShow(true);
  };
  const HandlePDFWarningClose = () => {
    setPDFWarningShow(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popRef.current && !popRef.current.contains(event.target)) {
        setPDFWarningShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    const cursorPosition = e.target.selectionStart;
    cursorPositionRef.current = cursorPosition;

    try {
      await schema.validateAt(name, { [name]: value });
      setErrors({ ...errors, [name]: "" });
    } catch (error) {
      setErrors({ ...errors, [name]: error.message });
    }

    setReport({ ...report, [name]: value });
    setTimeout(() => {
      e.target.setSelectionRange(
        cursorPositionRef.current,
        cursorPositionRef.current
      );
    }, 0);
  };

  const handleCheckboxChange = (e) => {
    setOverrideCompletenessCheck(e.target.checked);
    
  };

  const handleFileChange = async (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    try {
      await schema.validateAt(name, { [name]: file });
      setErrors({ ...errors, [name]: "" });
    } catch (error) {
      setErrors({ ...errors, [name]: error.message });
    }
    setReport({ ...report, [name]: file });
    setSelectedFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await schema.validate(report, { abortEarly: false });
      CallApi();
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  const CallApi = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("File", selectedFile);
    let projectData = {
      productId: 1,
      userId: userInfo?.id,
      email: userInfo?.email !== null ? userInfo?.email : "",
      city: CountyName ? CountyName : cityName,
      projectName: report.projectName,
      county: CountyName ? CountyName : "",
      countyId: countyId,
      reviewType: report?.typereview,
      latLng: "-73.985, 40.748",
      lotBlock: "10",
      lotNumber: "25",
      pcn: report.ProjectNumber,
      siteAddress: report.siteAddress,
      status: "In Progress",
      communityId: "7823616",
      propertyId: "5464",
      contractor: report.projectName,
      zoningId: report?.zoningdistrict,
      overrideCC: overrideCompletenessCheck,
    };

    dispatch(createProject(projectData))
      .unwrap()
      .then((data) => {
        if (data?.isSuccess == false) {
          setErrorMsg(data.message);
        } else {
          let fileData = {
            File: selectedFile,
            UserId: data?.userId,
            ProjectName: data?.projectName,
            ProjectId: data?.projectId,
          };
          dispatch(saveProjectFile(fileData))
            .unwrap()
            .then((data) => {
              console.log("data", data);
              setLoading(false);
              localStorage.setItem("listMessage", true);
              navigate("/blitz-planning");
            })
            .catch((e) => {
              setLoading(false);
              if (e.message == "Request failed with status code 400") {
                setErrorMsg("Project already exists");
              }
              setTimeout(() => {
                setErrorMsg("");
              }, 5000);
              console.log(e, "error");
            });
        }
      })
      .catch((e) => {
        setLoading(false);
        setErrorMsg("Project already exists");
        setTimeout(() => {
          setErrorMsg("");
        }, 5000);
        console.log(e, "error");
      });
  };

  const handleLebel = async (e, lebelName) => {
    const { name, value } = e.target;
    try {
      await schema.validateAt(name, { [name]: value });
      setErrors({ ...errors, [name]: "" });
    } catch (error) {
      setErrors({ ...errors, [name]: error.message });
    }
    setReport({ ...report, [name]: value });
    setSite(lebelName);
    setlebelText(lebelName == "pid" ? "PID" : "Address");
  };

  const handleReviewInputChange = async (e, value) => {
    const { name } = e.target;
  
    const newValues = Array.isArray(value) ? value : [value];

    const updatedReviewData = e.target.checked
      ? [...new Set([...reviewData, ...newValues])]
      : reviewData.filter((item) => !newValues.includes(item));
  
    try {
      await schema.validateAt(name, { [name]: updatedReviewData });
      setErrors({ ...errors, [name]: "" });
    } catch (error) {
      setErrors({ ...errors, [name]: error.message });
    }

    setReviewData(updatedReviewData);
    setReport({ ...report, typereview: updatedReviewData });
  };

  return (
    <>
      <div className="main-div">
        <form onSubmit={handleSubmit} className="form-inner-gap">
          <div
            className="row-1"
            style={{ borderBottom: "2px solid #E6E6E6", paddingBottom: "10px" }}
          >
            <div className="main-head">Add New Project</div>
          </div>
          {errorMsg !== "" ? (
            <>
              <div className="error-div">
                <div>
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ color: "red", fontSize: "14px" }}
                  />
                </div>
                <div>
                  <span className="error-text">{errorMsg}</span>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div>
            <div className="main-div-col">
              <div className="div-col-2">
                <div className="field-flex-parent">
                  <div className="field-flex">
                    <label htmlFor="projectName">
                      <span className="red-star">* </span>Project Name
                    </label>
                    <input
                      type="text"
                      id="projectName"
                      className="input-style"
                      placeholder="Enter Project Name"
                      name="projectName"
                      value={report?.projectName}
                      onChange={handleInputChange}
                    />
                    <div className="required-message">
                      {" "}
                      {errors.projectName !== undefined ? (
                        <span>{errors.projectName}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="field-flex">
                    <label htmlFor="input2">
                      <span className="red-star"></span>Description
                    </label>
                    <input
                      type="text"
                      className="input-style"
                      id="input2"
                      placeholder="Enter Description"
                    />
                  </div>
                  <div className="field-flex">
                    <label htmlFor="input2" className="lebel-text">
                      <span className="red-star"></span>County/City
                    </label>
                    {countyData.length >= 1 ? (
                      <>
                        <select
                          className="form-select"
                          name="CountyName"
                          value={countyId || ""}
                          disabled={countyData.length === 1}
                          onChange={async (e) => {
                            const selectedCountyId = e.target.value;
                            setCountyId(selectedCountyId);
                            const selectedCountyName = e.target.options[e.target.selectedIndex].text;
                            setCountyName(selectedCountyName);
                            await handleSelection(selectedCountyId);
                          }}
                        >
                          {countyData.length > 1 && (
                            <option value="">Select County</option>
                          )}
                          {countyData.map((item, key) => (
                            <option key={key} value={item.id}>
                              {item.countyName}
                            </option>
                          ))}
                        </select>
                      </>
                    ) : (
                      <>
                        <select
                          className="form-select"
                          name="CountyName"
                          value={countyId}
                          disabled
                        >
                          <option value="">No counties available</option>
                        </select>
                      </>
                    )}
                    <div>
                      {" "}
                      {errors.CountyName !== undefined ? (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errors.CountyName}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="field-flex">
                    <label htmlFor="input2" className="lebel-text">
                      <span className="red-star">* </span>Zoning District
                    </label>

                    <select
                      className="form-select"
                      name="zoningdistrict"
                      value={report?.zoningdistrict}
                      onChange={handleInputChange}
                    >
                      <option value="0">Select Zoning District</option>
                      {zoningdistrict &&
                        zoningdistrict.map((item, key) => (
                          <option key={key} value={item?.id}>
                            {item?.zoningCode + " " + item?.zoningName}
                          </option>
                        ))}
                    </select>

                    <div className="required-message">
                      {" "}
                      {errors.zoningdistrict !== undefined ? (
                        <span>{errors.zoningdistrict}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {reviewType.length >= 1 ? (
                    <>
                      <div className="field-flex">
                        <label htmlFor="input2" className="lebel-text">
                          <span className="red-star">* </span>Review Type
                        </label>

                        <div className="checkbox-container">
                        {reviewType.map((review) => (
                          <div className="checkbox-cont-child" key={review.id}>
                            <input
                              type="checkbox"
                              id={`checkbox${review.id}`}
                              name="typereview"
                              value={review.reviewTypeIds.join(",")}
                              onChange={(e) => handleReviewInputChange(e, review.reviewTypeIds)}
                            />
                            <label htmlFor={`checkbox${review.id}`}>
                              {review.reviewType}
                            </label>
                          </div>
                        ))}
                        </div>
                        <div className="required-message">
                          {" "}
                          {errors.typereview && <div>{errors.typereview}</div>}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="div-col-1">
                <div className="field-flex">
                  <label htmlFor="input1">
                    <span className="red-star">* </span>Identify Site by
                  </label>
                  <div className="radio-parent-big">
                    <div className="radio-field">
                      <input
                        type="radio"
                        name="identify"
                        id="identify"
                        value="address"
                        checked={report?.identify !== site ? true : false}
                        onChange={(e) => handleLebel(e, "Address")}
                      />
                      <label htmlFor="identify" className="radio-text">
                        Address
                      </label>
                    </div>

                    <div className="radio-field">
                      <input
                        type="radio"
                        name="identify"
                        id="identify_"
                        value="pid"
                        checked={report?.identify == site ? true : false}
                        onChange={(e) => handleLebel(e, "pid")}
                      />
                      <label htmlFor="identify_" className="radio-text">
                        Parcel Identification Number (PID)
                      </label>
                    </div>
                  </div>
                </div>
                <div className="field-flex">
                  <label htmlFor="siteAddress">
                    <span className="red-star">* </span>
                    {lebelText}
                  </label>
                  <input
                    type="text"
                    id="siteAddress"
                    className="input-style"
                    name="siteAddress"
                    placeholder={
                      lebelText === "Address" ? "Enter Address" : "Enter PID"
                    }
                    value={report?.siteAddress}
                    onChange={handleInputChange}
                  />
                  <div className="required-message">
                    {" "}
                    {errors.siteAddress !== undefined ? (
                      <span>{errors.siteAddress}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="field-flex" style={{ position: "relative" }}>
                  <div className="file-upload-div">
                    <label htmlFor="input1">
                      <span className="red-star">* </span>File Upload
                    </label>
                    <div
                      onClick={HandlePDFWarning}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={PDFWarning} className="PDFWarning" />
                    </div>
                  </div>
                  <div className="uploadParent">
                    <img src={UploadIcon} className="upload-icon" />
                    <input
                      type="file"
                      name="pdfname"
                      className="input-style-file"
                      onChange={handleFileChange}
                    />
                  </div>
                  <div className="required-message">
                    {" "}
                    {errors.pdfname !== undefined ? (
                      <span>{errors.pdfname}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {" "}
                    {errors.File !== undefined ? (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.File}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {PDFWarningShow && (
                    <div className="warning-pop" ref={popRef}>
                      <div>
                        <img
                          src={Cross}
                          onClick={HandlePDFWarningClose}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="warning-test">
                        Only <span className="weight-text">.pdf</span> files are
                        allowed for your account.
                        <br /> Upgrade your subscription to upload{" "}
                        <span className="weight-text">.dwg</span> files.
                      </div>
                    </div>
                  )}
                </div>
                {completenessCheckEnabled && (
                <div className="checkbox-cont-child">
                <input
                type="checkbox"
                checked={overrideCompletenessCheck}
                onChange={handleCheckboxChange}
                className="completenessCheck"
              />
                <label className="CheckLabel"> Override Completeness Check (Perform full checks even if completeness fails)</label>
                </div>
                )}
              </div>
              <div className="div-col-3"></div>
            </div>
          </div>

          <div className="row-4-flex">
            <button type="submit" className="button-prime" onClick={()=>setCollapsed(false)}>
              Submit
            </button>
            <button
              type="button"
              onClick={() => 
                {
                  navigate(-1); 
                  setCollapsed(false);
                }}
              className="button-sec"
            >
              Cancel
            </button>
          </div>
        </form>
        <div className={loading ? "overlay" : ""}>
          {loading && (
            <div className="loade-new">
              <div className="loader-icon">
                <img src={OneminGIF} className="UploadingGif" alt="onemint" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NewZenithProject;
