import { configureStore } from '@reduxjs/toolkit'
import registerReducer from './registerSlice';
import loginReducer from './loginSlice';
import projectReducer from './projectSlice';
import fileReducer  from './fileSlice';
import feedbackReducer from "../../Storage/Redux/feedbackFormSlice";
import adminuserReducer from './adminuserSlice';
const reducer = {
  register: registerReducer,
  login:loginReducer,
  project:projectReducer,
  file:fileReducer,
  adminuser:adminuserReducer,
  feedback: feedbackReducer,
}
const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;