import React from "react";
import "./Reassign.css";
 
const Reassign = () => {
  return (
      <div className="main-div">
          <div className="row-1">
            <div className="main-head heading1">Reassign Subscription</div>
        </div>
          <div className="row-2">
            <div >Reassign Subscription From:</div>
            <div className="data-flex-row">
              <div >
                <div>Member Name </div>
                <div className="user-data">Taylor Blitz</div>
              </div>
              <div >
                <div>Email</div>
                <div className="user-data">tailor.blitz@blitzpermits.ai</div>
              </div>
            </div>
          </div>
          <div className="row-3">
          <div className="field-rows-flex">
          <div className="field-rows-flex-child">
            <div className="label-input-flex">
              <label htmlFor="input1" >
                <span className="red-star">*</span> First Name
              </label>
              <input
                type="text"
                className="input-style"
                id="input1"
                placeholder="Enter first name"
              />
            </div>
            <div className="label-input-flex">
              <label htmlFor="input2" className="lebel-text">
                Middle Name
              </label>
              <input
                type="text"
                className="input-style"
                id="input2"
                placeholder="Enter middle name"
              />
            </div>
            <div className="label-input-flex">
              <label htmlFor="input3" className="lebel-text">
                <span className="red-star">*</span> Last Name
              </label>
              <input
                type="text"
                className="input-style"
                id="input3"
                placeholder="Enter last name"
              />
            </div>
            </div>
       
            <div className="field-rows-flex-child">
            <div className="label-input-flex">
              <label htmlFor="input1" >
                <span className="red-star">*</span> Email
              </label>
              <input
                type="text"
                className="input-style"
                id="input1"
                placeholder="Enter email"
              />
            </div>
            <div className="label-input-flex">
              <label htmlFor="input2">
                <span className="red-star">*</span> Phone
              </label>
              <input
                type="text"
                className="input-style"
                id="input2"
                placeholder="Enter phone"
              />
            </div>
            <div className="label-input-flex">
              <label htmlFor="input3" >
                <span className="red-star">*</span> Company Name
              </label>
              <input
                type="text"
                className="input-style"
                id="input3"
                placeholder="Enter company name"
              />
            </div>
            </div>
            <div  className="field-rows-flex-child">
            <div className="label-input-flex">
              <label htmlFor="input1" >
                <span className="red-star">*</span> User type
              </label>
              <input
                type="text"
                className="input-style"
                id="input1"
                placeholder="Select user type"
              />
            </div>
            </div>
        <div className="row-4-flex">
            <button type="submit" className="button-prime">
                Save
              </button>
            <button type="submit" className="button-sec">
                Cancel
              </button>
      </div>
      </div>
</div>
    </div>
   
  );
};
 
export default Reassign;