import React from "react";
import { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./DashBoard.css";
import { DefaultizedPieValueType } from "@mui/x-charts";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";


const Zenith = () => {

  let navigate = useNavigate();
  useEffect(() => {
    const useretails = localStorage.getItem("UserInfo");
    const userInformation = JSON.parse(useretails);

    if (userInformation.userType !=="admin") {
      navigate("/");
    }
  
  }, []);

  const data = [
    { label: "Group A", value: 590, color: "#7DB8CA" },
    { label: "Group B", value: 400, color: "#6789A0" },
  ];

  const sizing = {
    margin: { right: 5 },
    width: 200,
    height: 200,
    legend: { hidden: true },
  };
  const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

  const getArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / TOTAL;
    return `${(percent * 100).toFixed(0)}%`;
  };

  return (
    <>
      <div className="main-grid">
        <div className="col-1-flex">
          <div className="box-1-flex">
            <div className="parent-radio">
              <div className="h4-font-types">Types of projects</div>
              <div>
                <div className="radio-child">
                  <div className="inner-child">
                    <input
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked
                    />
                    <label htmlFor="flexRadioDefault1" className="h4-font">
                      Land
                    </label>
                  </div>
                  <div className="inner-child">
                    <input
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                    />
                    <label htmlFor="flexRadioDefault2" className="h4-font">
                      Zoning
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-2-flex">
            <div>
              <div>
                <div className="h2-font">Subscriptions</div>
              </div>
              <div className="pie-flex">
                <div>
                  <PieChart
                    series={[
                      {
                        outerRadius: 90,
                        data,
                        arcLabel: getArcLabel,
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontSize: 14,
                      },
                    }}
                    {...sizing}
                  />
                </div>
                <div className="box-2-data">
                  <div className="box-2-child">
                    <div className="box-2-text">Total Licences</div>
                    <div className="num-org-2">500</div>
                  </div>
                  <div className="box-2-child">
                    <div className="box-2-text">Licences Used</div>
                    <div className="num-org-2">300</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-3-4-flex">
            <div className="box-3-flex">
              <div className="h4-font">Average Time taken per project</div>
              <div className="Avg-Prj-Flex">
                <div className="num-org">200</div>
                <div className="time-data">hrs</div>
              </div>
            </div>
            <div className="box-4-flex">
              <div className="h4-font"> Forecasted time saved</div>
              <div className="Avg-Prj-Flex">
                <div className="num-org">50</div>
                <div className="time-data">hrs</div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-2-flex">
          <div className="box-5-flex">
            <div>
              <div className="h2-font">
                Account level number of projects YTD Status
              </div>
              <div>
                <BarChart
                  xAxis={[
                    {
                      scaleType: "band",
                      data: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "oct",
                        "Nov",
                        "Dec",
                      ],
                    },
                  ]}
                  series={[
                    { data: [19, 17, 18, 19, 16, 19, 20, 18, 19, 16, 19, 15] },
                    { data: [17, 15, 16, 17, 13, 17, 19, 17, 18, 15, 17, 13] },
                  ]}
                  colors={["#6789A0", "#7FBCCE"]}
                  width={800}
                  height={300}
                />
              </div>
              <div className="parent-status">
                <div className="child-status">
                  <div className="square-box-1"></div>
                  <div className="small-font">Submitted</div>
                </div>
                <div className="child-status">
                  <div className="square-box-2"></div>
                  <div className="small-font">Completed</div>
                </div>
              </div>
            </div>
            <div className="box-5-2row">
              <div className="h2-font">Account level projects status</div>
              <div className="box-5-pie">
                <div>
                  <PieChart
                    series={[
                      {
                        outerRadius: 70,
                        data,
                        arcLabel: getArcLabel,
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontSize: 14,
                      },
                    }}
                    {...sizing}
                  />
                </div>
                <div className="box-5-data">
                  <div>
                    <div className="status-text">Submitted</div>
                    <div className="data-box-5">500</div>
                  </div>
                  <div>
                    <div className="status-text">Completed</div>
                    <div className="data-box-5">300</div>
                  </div>
                  <div>
                    <div className="status-text">In Progress</div>
                    <div className="data-box-5">200</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3-flex">
          <div className="box-3-flex">
            <div className="h4-font">Number of login done YTD</div>
            <div className="num-org-big">800</div>
          </div>
          <div className="box-3-flex">
            <div className="h4-font">Non-Compliances Identified YTD</div>
            <div className="Avg-Prj-Flex">
              <div className="num-org">100</div>
              <div className="time-data"></div>
            </div>
          </div>
          <div className="box-3-flex">
            <div className="h4-font">Blitz Compliances Checked</div>
            <div className="Avg-Prj-Flex">
              <div className="num-org">400</div>
              <div className="time-data"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Zenith;
