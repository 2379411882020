import "./Login.css";
import { React, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../../Storage/Redux/loginSlice";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUserInfo } from "../../../Storage/Redux/projectSlice";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import "./Login.css";
const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const cursorPositionRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [loginData, setLoginData] = useState({ username: "", password: "" });
  const [errorMsg, setErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const schema = Yup.object().shape({
    username: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
      password: Yup.string().required("Password is required"),
  });

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    const cursorPosition = e.target.selectionStart;
    cursorPositionRef.current = cursorPosition;

    try {
      await schema.validateAt(name, { [name]: value });
      setErrors({ ...errors, [name]: "" });
    } catch (error) {
      setErrors({ ...errors, [name]: error.message });
    }
    setLoginData({ ...loginData, [name]: value });
    setTimeout(() => {
      e.target.setSelectionRange(
        cursorPositionRef.current,
        cursorPositionRef.current
      );
    }, 0);
  };

  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    try {
      await schema.validate(loginData, { abortEarly: false });
      CallApiData();
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  const CallApiData = () => {
    setShowLoader(true);

    dispatch(login(loginData))
      .unwrap()
      .then((data) => {
        if (data.status == "200" || data.status == "OK") {
          localStorage.setItem("userEmail", data?.data?.email);
          setShowLoader(false);
          setErrorMsg("");

          if (data?.data?.message === "Token created") {
            localStorage.setItem(
              "userTokenInfo",
              JSON.stringify(data?.data?.response)
            );
            dispatch(getUserInfo(data?.data?.response?.userId))
              .unwrap()
              .then((userdata) => {
                localStorage.setItem("userEmail", userdata?.email);
                localStorage.setItem("UserInfo", JSON.stringify(userdata));

                const NavPage =
                  userdata?.userType === "admin"
                    ? "/dashboard"
                    : "/user-home";
                navigate(NavPage);
                window.location.reload();
              });
          } else if (data?.data?.responseObject === null) {
            navigate("/verify-code");
          } else {
            navigate("/reset-password", { state: data?.data });
          }
        } else {
          setShowLoader(false);
        }
      })
      .catch((e) => {
        console.log(e, "error in login", e?.code);
        setShowLoader(false);
        setErrorMsg("Invalid Credentials");
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="login-container-new">
      <div className="login-screen-gradient">
        <img
          src={require("../../../Assets/Images/new-gradient-background.png")}
          className="back-grad"
        />
      </div>
      <div className="new-login-main">
        <div className="login-form">
          <div className="logo-div">
            <img
              src={require("../../../Assets/Images/purple_logo_new.png")}
              alt="logo"
            />
          </div>
          <form onSubmit={handleSubmitLogin}>
            <div className="form-group">
              <label htmlFor="email" className="new-login-label">
                <span className="red-star">
                  <sup>*</sup>
                </span>
                Email
              </label>
              <input
                type="text"
                name="username"
                id="email"
                value={loginData.username || ""}
                onChange={handleInputChange}
                placeholder="Enter your email"
                className="input-new-login"
              />
              <div>
                {errors.username && (
                  <span className="error">{errors.username}</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="password-forgot-flex">
                <label htmlFor="password" className="new-login-label">
                  <span className="red-star">
                    <sup>*</sup>
                  </span>
                  Password
                </label>
                <Link to="/forgot-password" className="forgot-password">
                  Forgot?
                </Link>
              </div>
              <div className="password-input">
                <input
                  className="input-new-login"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={loginData.password || ""}
                  onChange={handleInputChange}
                  placeholder="Enter your password"
                />
                <button
                  type="button"
                  className="toggle-password"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </button>
              </div>
              <div>
                {errors.password && (
                  <span className="error">{errors.password}</span>
                )}
              </div>
            </div>
            {showLoader ? (
              <div style={{ textAlign: "center", marginTop: "0px" }}>
                <FontAwesomeIcon
                  icon={faSpinner}
                  color="#5b72da"
                  spin
                  size="2x"
                />
              </div>
            ) : (
              ""
            )}
            <button type="submit" className="login-button">
              Login
            </button>
            {errorMsg !== "" ? (
              <div className="failure-div">
                <div className="failure-text">{errorMsg}</div>
              </div>
            ) : (
              ""
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
export default Login;
