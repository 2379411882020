import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RegisterDataService from "../../Services/RegisterService";

const initialState = [];

export const register = createAsyncThunk(
  "register/registerUser",
  async (register) => {
    const res = await RegisterDataService.registerUser(register);
    return res.data;
    
  }
);

const registerSlice = createSlice({
  name: "register",
  initialState,
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      console.log(action, "serviceLogin", state);
      state.push(action.payload);
    },
  },
});

const { reducer } = registerSlice;
export default reducer;
