import http from "../http-file";

const saveProjectFile = async (fileData) => {
  return http.post(`/api/File/upload`,fileData);
};
const getFileData = (fileData) => {
  return http.get(
    `/api/File/download?${fileData}`
  );
};
const FileService = {
  saveProjectFile,
  getFileData
};

export default FileService;
