import React,{useState} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Zenith from "../Components/Pages/Zenith/Zenith.js";
import Vertex from "../Components/Pages/Vertex/Vertex.js";
import Apex from "../Components/Pages/Apex/Apex.js";
import Sidebar from "../Components/Layouts/common/Sidebar.js";
import ShowSideNavBar from "../Components/Utils/ShowSideNavBar.js";
import ShowTopMenu from "../Components/Utils/ShowTopMenu.js";
import TopMenu from "../Components/Layouts/TopMenu.js";
import NewUser from "../Components/Pages/User/Reassign.js";
import DashBoard from "../Components/Pages/Admin/DashBoard.js";
import UserList from "../Components/Pages/Admin/UserList.js";
import NewZenithProject from "../Components/Pages/Project/NewZenithProject.js";
import NewVertexProject from "../Components/Pages/Project/NewVertexProject.js";
import NewApexProject from "../Components/Pages/Project/NewApexProject.js";
import User from "../Components/Pages/Admin/User.js";
import UserHome from "../Components/Pages/UserHome/UserHome.js";
import UserReports from "../Components/Pages/UserReports/UserReports.js";
import TrainingManual from "../Components/Pages/TrainingManual/TrainingManual.js";
import PageNotFound from "../Components/Pages/PageNotFound.js";
import Login from "../Components/Pages/User/Login.js";
import Verify from "../Components/Pages/VerifyCode/Verify.js";
import Reset from "../Components/Pages/ResetPassword/Reset.js";
import Forgot from "../Components/Pages/ForgotPassword/Forgot.js";

function App() {
const userData = localStorage.getItem("UserInfo");
const [collapsed, setCollapsed] = useState(false);

 
  return (
    <Router>
      <div className="sidebar-topMain">
      <div style={{backgroundColor:"#ffffff"}}>
      {userData === null || undefined ? "":
      <ShowSideNavBar>
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed}/>
      </ShowSideNavBar>
      }
      </div>

      <div className={(userData !== null) ? `side-flex ${collapsed ? 'collapsed' : ''}`:"side-flex-login"}>
        {userData === null || undefined ? "":
        <ShowTopMenu>
          <TopMenu />
        </ShowTopMenu>
        }

        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/verify-code" element={<Verify />} />
          <Route path="/reset-password" element={<Reset/>} />
          <Route path="/forgot-password" element={<Forgot/>} />
          <Route path="/blitz-planning" element={<Zenith setCollapsed={setCollapsed}/>} />
          <Route path="/blitz-commercial-building" element={<Vertex setCollapsed={setCollapsed}/>} />
          <Route path="/blitz-residential-building" element={<Apex setCollapsed={setCollapsed}/>} />
          <Route path="/user-home" element={<UserHome />} />   
          <Route path="/user-reports" element={<UserReports />} />     
          <Route path="/training-manual" element={<TrainingManual />} />
          <Route path="/reassign" element={<NewUser />} />
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/user-list" element={<UserList />} />
          <Route path="/new-blitz-planning-project" element={<NewZenithProject setCollapsed={setCollapsed}/>} />
          <Route path="/new-blitz-commercial-building-project" element={<NewVertexProject setCollapsed={setCollapsed}/>} />
          <Route path="/new-blitz-residential-building-project" element={<NewApexProject setCollapsed={setCollapsed}/>} />
          <Route path="/user" element={<User />} />
          <Route path="*" element={<PageNotFound />}/>
        </Routes>
      </div>
      </div>
    </Router>
  );
}

export default App;
