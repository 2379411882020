import React, { useState, useEffect } from 'react';
import "./CountDownTimer.css"

const CountdownTimer = ({productId}) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  function calculateTimeLeft() {
    const userDataInfo = localStorage.getItem("UserInfo");
    const userDetails = JSON.parse(userDataInfo);
    const licenseData = userDetails?.licenseData || [];
    const license = licenseData.find(item => item.productId === productId);

    if (!license || !license.validTo) return {};

    const validToDate = new Date(license.validTo);
    const now = new Date();
    const difference = validToDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className='TrialDiv'>
      {timeLeft.days !== undefined ? (<h4 className='TrialHeading'>Product License Expires in</h4>) : (
        <h4 className='TrialHeading'>No valid license found</h4>
      )}
      {timeLeft.days !== undefined ? (
        <div className='TrialTime'>
          {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
        </div>
      ) : (
        <p></p>
      )}
    </div>
    
  );
};

export default CountdownTimer;