import http from "../http-common";

const getClient = (userId) => {
  return http.get(`/api/project/client?userId=${userId}`);
};

const createProject = (data) => {
  return http.post("api/project", data);
};

const getCounty = (clientId) => {
  return http.get(`/api/project/county?clientId=${clientId}`);
};

const getReportChecksSummary = (clientId,timeFrame) => {
  return http.get(`/api/Dashboard/ReportChecksSummary/${clientId}/${timeFrame}`);
};

const getReportCountSummary = (clientId,timeFrame) => {
  return http.get(`/api/Dashboard/ReportCountSummary/${clientId}/${timeFrame}`);
};

const getReportByUser = (clientId,timeFrame) => {
  return http.get(`/api/Dashboard/ReportByUser/${clientId}/${timeFrame}`);
};

const GetReportAverageTime = (clientId,timeFrame) => {
  return http.get(`/api/Dashboard/ReportAverageTime/${clientId}/${timeFrame}`);
};

const GetReportTime = (clientId,timeFrame) => {
  return http.get(`/api/Dashboard/ReportTime/${clientId}/${timeFrame}`);
};

const getUserInfo = async (UserId) => {
  console.log(UserId);
  return http.get(`/api/user/${UserId}`);
 
};

const getPlanType = async (productId) => {
  console.log(productId);
  return http.get(`/api/project/plan-type?ProductId=${productId}`);
};

const getConstructionType = async (productId) => {
  console.log(productId);
  return http.get(`/api/project/construction-type?ProductId=${productId}`);
};

const getProjectList = (data) => {
  return http.get(`/api/project/${data}`);
};

const getReviewType = (reviewString) => {
  return http.get(`/api/project/reviewtype?clientId=${reviewString}`);
};

const getProductReviewType = (countyId, productId) => {
  return http.get(`/api/project/productReviewType?productId=${productId}&countyId=${countyId}`);
};

const getCountyProduct = (countyId, productId) => {
  return http.get(`/api/project/countyproductconfig?countyId=${countyId}&productId=${productId}`);
};

const getZoning=(zoingId)=>{
  return http.get(`/api/ZoningLandConfiguration/zoning/${zoingId}`);
}

const getQueuePositions=(projectIds)=>{
  return http.post(`/api/project/queue-positions`,{projectIds});
}

const ReportService = {
  createProject,
  getProjectList,
  getClient,
  getCounty,
  getReviewType,
  getUserInfo,
  getZoning,
  getCountyProduct,
  getQueuePositions,
  getPlanType,
  getConstructionType,
  getReportChecksSummary,
  getReportCountSummary,
  getReportByUser,
  GetReportAverageTime,
  GetReportTime,
  getProductReviewType
};

export default ReportService;
