import React, { useRef } from "react";
import "./NewZenithProject.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import OneminGIF from "../../../Assets/Images/30sec.gif";
import Cross from "../../../Assets/Images/cross.png";
import PDFWarning from "../../../Assets/Images/pdfwarning.png";
import UploadIcon from "../../../Assets/Images/uploadIcon.png";
import {
  getCounty,
  getReviewType,
  createProject,
  getZoning,
  // getPlanType,
  // getConstructionType
} from "../../../Storage/Redux/projectSlice";
import { saveProjectFile } from "../../../Storage/Redux/fileSlice";
const NewZenithProject = ({setCollapsed}) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const cursorPositionRef = useRef(null);
  const userData = localStorage.getItem("UserInfo");
  const userInfo = JSON.parse(userData);
  const [report, setReport] = useState({
    projectName: "",
    Zoning: "",
    siteAddress: "",
    pdfname: "",
  });
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [cityName, setCityName] = useState("");
  const [countyId, setCountyId] = useState("");
  const [lebelText, setlebelText] = useState("Address");
  const [countyData, setcountyData] = useState([]);
  const [CountyName, setCountyName] = useState("");
  const [site, setSite] = useState("address");
  const [loading, setLoading] = useState(false);
  const [PDFWarningShow, setPDFWarningShow] = useState(false);
  // const [planTypes, setPlanTypes] = useState([]);
  // const [constructionTypes, setConstructionTypes] = useState([]);
  // const [selectedPlanType, setSelectedPlanType] = useState();
  // const [selectedConstructionType, setSelectedConstructionType] = useState();
  const [projectDescription, setProjectDescription] = useState('');
  // const [selectedRadioId, setSelectedRadioId] = useState(15);

  const popRef = useRef(null);
  const schema = Yup.object().shape({
    projectName: Yup.string().required("Project Name is required"),
    siteAddress: Yup.string().required("Site Address is required"),
    pdfname: Yup.mixed().test("is-pdf", "Upload the PDF file", (value) => {
      if (!value) return false;
      const fileName = value.name.toLowerCase();
      return (
        fileName.endsWith(".pdf") ||
        fileName.endsWith(".dxf") ||
        fileName.endsWith(".dwg")
      );
    })
    .test("fileSize", "For Trial, the file must be less than 40 MB", (value) => {
      if (!value) return true;
      const fileSizeInMB = value.size / (1024 * 1024);
      return fileSizeInMB <= 40;
    }),
  });

  useEffect(() => {
    const userData = localStorage.getItem("UserInfo");
    const userInfo = JSON.parse(userData);
    if (userInfo === null || undefined) {
      navigate("/");
    }
    setCityName("");
    setCountyId("");
    dispatch(getCounty(userInfo?.clientId))
      .unwrap()
      .then(async (data) => {
        if (data?.statusCode == "200" || data?.statusCode == "OK") {
          setcountyData(data?.resultObj);
          const { name, value } = {};
          try {
            await schema.validateAt(name, { [name]: value });
            setErrors({ ...errors, [name]: "" });
          } catch (error) {
            setErrors({ ...errors, [name]: error.message });
          }
          setReport({ ...report, [name]: value });
          setCountyId(value);
          setCityName(data?.resultObj[0]?.cityName);
          setCountyName(data?.resultObj[0]?.countyName);
          dispatch(getZoning(userInfo?.clientId))
            .unwrap()
            .then((response) => {
              console.log(response);
            });
          dispatch(
            getReviewType(
              `${userInfo?.clientId}&countyId=${data?.resultObj[0]?.id}`
            )
          )
            .unwrap()
            .then((data) => {
              if (data?.statusCode == "200" || data?.statusCode == "OK") {
                //   setReviewType(data?.resultObj);
              }
            })
            .catch((e) => {
              console.log(e, "error");
            });
        }
      })
      .catch((e) => {
        console.log(e, "error");
      });
  }, []);

  const handleDescChange = (e) => {
    setProjectDescription(e.target.value);
  };

  // useEffect(() => {
  //   dispatch(getPlanType(2))
  //     .unwrap()
  //     .then((data) => {
  //       if (data?.statusCode === "200" || data?.statusCode === "OK") {
  //         setPlanTypes(data?.resultObj);
  //         const firstActiveType = data.resultObj.find(type => type.isActive);
  //         if (firstActiveType) {
  //           setSelectedPlanType(firstActiveType.id);
  //         } else if (data.resultObj.length > 0) {
  //           setSelectedPlanType(data.resultObj[0].id);
  //         }
  //       } else {
  //         console.error('Failed to fetch plan types:', data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching plan types:', error);
  //     });
  // }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getConstructionType(2))
  //     .unwrap()
  //     .then((data) => {
  //       if (data?.statusCode === "200" || data?.statusCode === "OK") {
  //         setConstructionTypes(data?.resultObj);
  //         const firstActiveType = data.resultObj.find(type => type.isActive);
  //         if (firstActiveType) {
  //           setSelectedConstructionType(firstActiveType.id);
  //         } else if (data.resultObj.length > 0) {
  //           setSelectedConstructionType(data.resultObj[0].id);
  //         }
  //       } else {
  //         console.error('Failed to fetch construction types:', data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching construction types:', error);
  //     });
  // }, [dispatch]);

  // const handleConstructionTypeChange = (event) => {
  //   setSelectedConstructionType(event.target.value);
  // };

  // const handleOptionChange = (event) => {
  //   setSelectedPlanType(event.target.value);
  // };

  const HandlePDFWarning = () => {
    setPDFWarningShow(true);
  };
  const HandlePDFWarningClose = () => {
    setPDFWarningShow(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popRef.current && !popRef.current.contains(event.target)) {
        setPDFWarningShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  // const handleRadioChange = (e) => {
  //   const selectedId = parseInt(e.target.value); 
  //   setSelectedRadioId(selectedId);
  // };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    const cursorPosition = e.target.selectionStart;
    cursorPositionRef.current = cursorPosition;
    try {
      await schema.validateAt(name, { [name]: value });
      setErrors({ ...errors, [name]: "" });
    } catch (error) {
      setErrors({ ...errors, [name]: error.message });
    }
    setReport({ ...report, [name]: value });
    setTimeout(() => {
      e.target.setSelectionRange(
        cursorPositionRef.current,
        cursorPositionRef.current
      );
    }, 0);
  };

  const handleFileChange = async (e) => {
    const { name } = e.target;
    try {
      await schema.validateAt(name, { [name]: e.target.files[0] });
      setErrors({ ...errors, [name]: "" });
    } catch (error) {
      setErrors({ ...errors, [name]: error.message });
    }
    setReport({ ...report, [name]: e.target.files[0] });
    setSelectedFile(e.target.files[0]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await schema.validate(report, { abortEarly: false });
      CallApi();
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  const CallApi = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("File", selectedFile);
    let projectData = {
      productId: 2,
      userId: userInfo?.id,
      email: userInfo?.email !== null ? userInfo?.email : "",
      city: cityName ? cityName : "",
      projectName: report.projectName,
      county: CountyName ? CountyName : "",
      countyId: userInfo?.clientId,
      // reviewType: [selectedRadioId],
      latLng: "-73.985, 40.748",
      lotBlock: "10",
      lotNumber: "25",
      pcn: report.ProjectNumber,
      siteAddress: report.siteAddress,
      status: "In Progress",
      communityId: "7823616",
      propertyId: "5464",
      contractor: report.projectName,
      zoningId: 1,
      // planType: selectedPlanType,
      // constructionType: selectedConstructionType,
      projectDescription: projectDescription,
    };

    dispatch(createProject(projectData))
      .unwrap()
      .then((data) => {
        if (data?.isSuccess == false) {
          setLoading(false);
          setErrorMsg(data.message);
        } else {
          let fileData = {
            File: selectedFile,
            UserId: data?.userId,
            ProjectName: data?.projectName,
            ProjectId: data?.projectId,
          };
          dispatch(saveProjectFile(fileData))
            .unwrap()
            .then((data) => {
              console.log("data", data);
              setLoading(false);
              localStorage.setItem("listMessage", true);
              navigate("/blitz-commercial-building");
            })

            .catch((e) => {
              setLoading(false);
              if (e.message == "Request failed with status code 400") {
                setErrorMsg("Project already exists");
              }

              setTimeout(() => {
                setErrorMsg("");
              }, 5000);
              console.log(e, "error");
            });
        }
      })
      .catch((e) => {
        setLoading(false);
        setErrorMsg("Project already exists");
        setTimeout(() => {
          setErrorMsg("");
        }, 5000);
        console.log(e, "error");
      });
  };

  const handleLebel = async (e, lebelName) => {
    const { name, value } = e.target;
    try {
      await schema.validateAt(name, { [name]: value });
      setErrors({ ...errors, [name]: "" });
    } catch (error) {
      setErrors({ ...errors, [name]: error.message });
    }

    setReport({ ...report, [name]: value });
    setSite(lebelName);
    setlebelText(lebelName == "pid" ? "PID" : "Address");
  };

  return (
    <>
      <div className="main-div">
        <form onSubmit={handleSubmit} className="form-inner-gap">
          <div
            className="row-1"
            style={{ borderBottom: "2px solid #E6E6E6", paddingBottom: "10px" }}
          >
            <div className="main-head">Add New Project</div>
          </div>
          {errorMsg !== "" ? (
            <>
              <div className="error-div">
                <div>
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ color: "red", fontSize: "14px" }}
                  />
                </div>
                <div>
                  <span className="error-text">{errorMsg}</span>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          <div>
            <div className="main-div-col">
              <div className="div-col-2">
                <div className="field-flex-parent">
                  <div className="field-flex">
                    <label htmlFor="projectName">
                      <span className="red-star">* </span>Project Name
                    </label>
                    <input
                      type="text"
                      id="projectName"
                      className="input-style"
                      placeholder="Enter Project Name"
                      name="projectName"
                      value={report?.projectName}
                      onChange={handleInputChange}
                    />
                    <div className="required-message">
                      {" "}
                      {errors.projectName !== undefined ? (
                        <span>{errors.projectName}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="field-flex">
                      <label htmlFor="input2">
                        <span className="red-star"></span>Description
                      </label>
                      <input
                        type="text"
                        className="input-style"
                        id="input2"
                        placeholder="Enter Description"
                        value={projectDescription}
                        onChange={handleDescChange}
                      />
                  </div>
                  {/* <div className="field-flex">
                  <label htmlFor="property-type">
                    <span className="red-star"></span>Plan Type
                  </label>
                  <div className="radio-options">
                    {planTypes.map((plan) => (
                      <label key={plan.id} className="flex-row-label">
                        <input
                          type="radio"
                          name="property-type"
                          value={plan.id}
                          checked={selectedPlanType === plan.id}
                          onChange={handleOptionChange}
                          disabled={!plan.isActive}
                          className="input-style"
                        />
                        {plan.description}
                      </label>
                    ))}
                  </div>
                </div> */}
                  <div className="field-flex">
                    <label htmlFor="input2" className="lebel-text">
                      <span className="red-star"></span>County / City
                    </label>
                    {countyData.length >= 1 ? (
                      <>
                        {" "}
                        <select
                          className="form-select"
                          name="CountyName"
                          value={countyId}
                          disabled
                        >
                          {countyData &&
                            countyData.map((item, key) => (
                              <option key={key} value={item?.id}>
                                {item?.countyName}
                              </option>
                            ))}
                        </select>
                      </>
                    ) : (
                      <>
                        <select
                          className="form-select"
                          name="CountyName"
                          value={countyId}
                        >
                          <option value="">Select County</option>
                        </select>
                      </>
                    )}
                    <div className="required-message">
                      {" "}
                      {errors.CountyName !== undefined ? (
                        <span>{errors.CountyName}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                
              </div>
              <div className="div-col-1">
              <div className="field-flex">
                  <label htmlFor="input1">
                    <span className="red-star">* </span>Identify Site by
                  </label>
                  <div className="radio-parent-big">
                    <div className="radio-field">
                      <input
                        type="radio"
                        name="identify"
                        id="identify"
                        value="address"
                        checked={report?.identify !== site ? true : false}
                        onChange={(e) => handleLebel(e, "Address")}
                      />
                      <label htmlFor="identify" className="radio-text">
                        Address
                      </label>
                    </div>

                    <div className="radio-field">
                      <input
                        type="radio"
                        name="identify"
                        id="identify_"
                        value="pid"
                        checked={report?.identify == site ? true : false}
                        onChange={(e) => handleLebel(e, "pid")}
                      />
                      <label htmlFor="identify_" className="radio-text">
                        Parcel Identification Number (PID)
                      </label>
                    </div>
                  </div>
                </div>
                <div className="field-flex">
                  <label htmlFor="siteAddress">
                    <span className="red-star">* </span>
                    {lebelText}
                  </label>
                  <input
                    type="text"
                    id="siteAddress"
                    className="input-style"
                    name="siteAddress"
                    placeholder={
                      lebelText === "Address" ? "Enter Address" : "Enter PID"
                    }
                    value={report?.siteAddress}
                    onChange={handleInputChange}
                  />
                  <div className="required-message">
                    {" "}
                    {errors.siteAddress !== undefined ? (
                      <span>{errors.siteAddress}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="field-flex" style={{ position: "relative" }}>
                  <div className="file-upload-div">
                    <label htmlFor="input1">
                      <span className="red-star">* </span>File Upload
                    </label>
                    <div
                      onClick={HandlePDFWarning}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={PDFWarning} className="PDFWarning" />
                    </div>
                  </div>
                  <div className="uploadParent">
                    <img src={UploadIcon} className="upload-icon" />
                    <input
                      type="file"
                      name="pdfname"
                      className="input-style-file"
                      onChange={handleFileChange}
                    />
                  </div>
                  <div className="required-message">
                    {" "}
                    {errors.pdfname !== undefined ? (
                      <span>{errors.pdfname}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="required-message">
                    {" "}
                    {errors.File !== undefined ? (
                      <span>{errors.File}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  {PDFWarningShow && (
                    <div className="warning-pop" ref={popRef}>
                      <div>
                        <img
                          src={Cross}
                          onClick={HandlePDFWarningClose}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="warning-test">
                        Only <span className="weight-text">.pdf</span> files are
                        allowed for your account.
                        <br /> Upgrade your subscription to upload{" "}
                        <span className="weight-text">.dwg</span> files.
                      </div>
                    </div>
                  )}
                {/* <div className="field-flex">
                  <div className="radio-options">
                    <label className="flex-row-label">
                      <input
                        type="radio"
                        name="preApp"
                        value="14"
                        checked={selectedRadioId === 14}
                        onChange={handleRadioChange}
                        className="input-style"
                      />
                      Pre-Application Checks Only
                    </label>
                    <label className="flex-row-label">
                      <input
                        type="radio"
                        name="compliance"
                        value="15"
                        checked={selectedRadioId === 15}
                        onChange={handleRadioChange}
                        className="input-style"
                      />
                      Pre App and Compliance Checks
                    </label>
                  </div>
                </div> */}
                </div>
                {/* <div className="field-flex">
                  <label htmlFor="construction-type">
                    <span className="red-star"></span>Construction Type
                  </label>
                  <div className="radio-options">
                    {constructionTypes.map((type) => (
                      <label key={type.id} className="flex-row-label">
                        <input
                          type="radio"
                          name="construction-type"
                          value={type.id}
                          checked={selectedConstructionType === type.id}
                          onChange={handleConstructionTypeChange}
                          disabled={!type.isActive}
                          className="input-style"
                        />
                        {type.constructionType}
                      </label>
                    ))}
                  </div>
                </div> */}
              </div>
              <div className="div-col-3"></div>
            </div>
          </div>
          <div className="row-4-flex" style={{ paddingTop: "70px" }}>
            <button type="submit" className="button-prime" onClick={()=>setCollapsed(false)}>
              Submit
            </button>
            <button
              type="button"
              onClick={() => 
                {
                  navigate(-1); 
                  setCollapsed(false);
                }}
              className="button-sec"
            >
              Cancel
            </button>
          </div>
        </form>
        <div className={loading ? "overlay" : ""}>
          {loading && (
            <div className="loade-new">
              <div className="loader-icon">
                <img src={OneminGIF} className="UploadingGif" alt="onemint" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NewZenithProject;
