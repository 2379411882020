import http from "../http-common";

const login = (data) => {
 return http.post("api/authentication/login", data) 
};

const verifyCode = (data) => {
  return http.post("api/authentication/login-2FA", data);
};

const confirmEmail = (data) => {
  return http.post("api/authentication/confirm-email", data);
};

const resetPassword = (data) => {
  return http.post("api/authentication/reset-password", data);
};

const forgotPassword = (forgotData) => {
  return http.post(`api/authentication/forgotpassword?email=` + forgotData);
};

const LoginService = {
  confirmEmail,
  login,
  verifyCode,
  forgotPassword,
  resetPassword,
};

export default LoginService;
