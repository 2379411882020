import http from "../http-common";

const registerUser = (registerData) => {
  return http.post("api/authentication/register", registerData);
};

const RegisterService = {
  registerUser,
};

export default RegisterService;
