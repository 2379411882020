
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FileDataService from "../../Services/FileService";

const initialState = [];

export const saveProjectFile = createAsyncThunk(
    "file/saveProjectFile",
    async (projectFile) => {
      const res = await FileDataService.saveProjectFile(projectFile);
      return res.data;
    }
  );

export const getDownloadFileData = createAsyncThunk(
  "file/getFileData",
  async (fileData) => {
    const res = await FileDataService.getFileData(fileData);
    return res.data;
  }
);

const fileSlice = createSlice({
  name: "file",
  initialState,
  extraReducers: {
    [saveProjectFile.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
    [getDownloadFileData.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
  },
});

const { reducer } = fileSlice;
export default reducer;