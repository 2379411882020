import * as React from 'react';
import PageNotFound from "../../Assets/Images/404.png";
import "./PageNotFound.css";
import { Link } from "react-router-dom";
const PageNotFound1 = () => {
  return (
      <div className="main-div" style={{alignItems:"center"}}>
          <img src={PageNotFound} className='pageNotFoundImage'/>
          <div>
            <p className='notFoundPara'>The page you are looking for could not be found. We suggest you to go back to home.</p>
          </div>
          <div>
          <Link to={"/user-home"}>
              <button className="login-button" style={{paddingInline:"60px"}}>
              Back to Home
              </button>
          </Link>
          </div>
      </div>
  );
};

export default PageNotFound1;
