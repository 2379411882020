import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./UserList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { getUserList } from "../../../Storage/Redux/adminuserSlice";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import dropDownArrow from "../../../Assets/Images/dropdownarrow.png"

const UserList = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const showSuccessMsg = localStorage.getItem("listMessage");
  const [userList, setUserList] = useState([]);
  const [errorMsg, setErrorMsg] = useState("User created successfully");
  const [apiLoaded, setApiLoaded] = useState(false);
  const [closeMsgBox, setCloseMsgBox] = useState(showSuccessMsg);
  const [iconRefresh, setIconRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  // const userDataInfo = localStorage.getItem("UserInfo");
  // const userDetails = JSON.parse(userDataInfo);
  // console.log("userDetails ", userDetails);
  // const userData = localStorage.getItem("UserInfo");
  // const userInfo = JSON.parse(userData);
  // console.log(userInfo, "LOCAL STORAGE ATA");
  const [currentPage, setCurrentPage] = useState(1);
  const [listPerPage, setListPerPage] = useState(10);
  const [saveUi, setSaveUi] = useState("");
  const [selectedOption, setSelectedOption] = useState("Last 24 hours");

  useEffect(() => {
    setErrorMsg("User created successfully");
    const userDetails = localStorage.getItem("UserInfo");
    const userInformation = JSON.parse(userDetails);

    if (userInformation.userType !== "admin") {
      navigate("/");
    }

    setTimeout(() => {
      setCloseMsgBox(false);
    }, 3000);

    dispatch(getUserList(selectedOption))
      .unwrap()
      .then((data) => {
         setUserList(data);
        setApiLoaded(true);
        localStorage.setItem("listMessage", false);
        setSaveUi(callTableBody(data,currentPage,listPerPage));

      });

  }, [selectedOption,currentPage]);
  const callTableBody = (data,currentPage,listPerPage) => {
   
    let currentList=[];
  
    if(data.length<10){
      currentList = data;
     }
     else{
      const indexOfLastTodo = currentPage * listPerPage;
      const indexOfFirstTodo = indexOfLastTodo - listPerPage;
      currentList = data.slice(indexOfFirstTodo, indexOfLastTodo);
     }

  
    const renderTodos =
      currentList &&
      currentList.map((item, key) => {
        return (
          <tbody className="table-body" key={key}>
            <tr key={key} className="table-row-tblrw ">
              <td className="td-projectlist-tbl text-start ml-5">
                {item?.firstName + " " + item.surname}
              </td>
              <td className="td-projectlist-tbl  text-start ml-5">
                {item?.email}
              </td>
              {/* <td></td>
              <td></td> */}
              <td className="td-projectlist-tbl   text-start ml-5">
                {item?.status == 1 ? "Active" : "Inactive"}
              </td>
              <td className="td-projectlist-tbl">
                {item?.projectInPrgrss + item?.projectCmpltd} Projects Submitted
              </td>
              <td className="td-projectlist-tbl">{item?.projectInPrgrss} In-Progress</td>
              <td className="td-projectlist-tbl">{item?.projectCmpltd} Completed</td>
            </tr>
          </tbody>
        );
      });
    return renderTodos;
  };

  const handleCloseMsg = () => {
    setCloseMsgBox("false");
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(userList.length / listPerPage); i++) {
    pageNumbers.push(i);
  }
  const handleClick = (event) => {
    
    setCurrentPage(event.target.id);
    setListPerPage(10);
    
  };

  const renderPageNumbers = pageNumbers.map((number) => {
    return (
      <li className={currentPage==number?"active-list":""}key={number} id={number} onClick={handleClick}>
        {number}
      </li>
    );
  });

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filteredList = userList.filter(
      (user) =>
        user.firstName.toLowerCase().includes(query.toLowerCase()) ||
        user.surname.toLowerCase().includes(query.toLowerCase()) ||
        user.email.toLowerCase().includes(query.toLowerCase())
    );
    setSaveUi(callTableBody(filteredList,currentPage,listPerPage));
  
  };

  const handleRefresh = (e, currentPage) => {
    setIconRefresh(true);
    const params = {
      page: currentPage,
    };
    const queryString = Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    const encodedUrl = `?${queryString}`;
    navigate(encodedUrl);
    window.location.reload();
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <div className="main-div">
        <div className="top-row" style={{ borderBottom: "2px solid #E6E6E6", paddingBottom:"10px"}}>
          <div className="active-heading">
            <h4 className="main-head">Active Users</h4>
          </div>
          <div className="inner-box-user-list">
            <div className="search-container">
              <FontAwesomeIcon
                icon={faSearch}
                style={{ color: "#666666" }}
                className="search-icon"
              />
              <input
                className="input-box-user"
                type="search"
                placeholder="Search here"
                aria-label="Search"
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <button
              type="button"
              className="btn-project-refresh"
              onClick={(e) => handleRefresh(e, currentPage)}
            >
              <FontAwesomeIcon
                icon={faSync}
                size={"1px"}
                color={"#543CA1"}
                spin={iconRefresh}
              />
            </button>
            <button type="button" className="btn-project">
              <Link to="/user">
                <span className="start-text">
                  {" "}
                  <span className="plus-icon">+</span> Add New User
                </span>
              </Link>
            </button>
          </div>
        </div>
        <div className="admin-filterBy-parent">
          <div className="filterBy-heading">
            <p style={{marginBottom: "0rem"}}>Filter by</p>
            <img src={dropDownArrow}/>
          </div>
        <div className="time-data">
        <select id="time-filter" value={selectedOption} onChange={handleChange} className="custom-dropdown-admin">
                  <option value="Last 24 hours">Last 24 Hours</option>
                   <option value="Last 7 days">Last 7 Days</option>
                   <option value="Last 30 days">Last 30 Days</option>
                   <option value="all">All</option>
          </select>
          </div>
        </div>
        {closeMsgBox == "true" ? (
          <>
            <div className="message-div">
              <div className="inner-div">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "#1E7137" }}
                />
                <span className="msg-text">{errorMsg}</span>
              </div>
              <div>
                <span onClick={handleCloseMsg} className="cross">
                  {" "}
                  X{" "}
                </span>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="table-div-completed">
          {saveUi.length > 0 ? (
            <>
              <table className="table table-borderless">
                <thead>
                  <tr className="table-heading">
                    <th className="th-projectlist-tbl">
                      User Name
                    </th>
                    <th className="th-projectlist-tbl">
                      Email
                    </th>
                    {/* <th className="th-projectlist-tbl">
                      Login (YTD)
                    </th>
                    <th className="th-projectlist-tbl">
                      Last Login
                    </th> */}
                    <th className="th-projectlist-tbl">
                      Status
                    </th>
                    <th className="th-projectlist-tbl">
                      Projects Submitted
                    </th>
                    <th className="th-projectlist-tbl">
                      Projects In-Progress
                    </th>
                    <th className="th-projectlist-tbl">
                      Projects Completed{" "}
                    </th>
                  </tr>
                </thead>
                {saveUi}
              </table>
            </>
          ) : (
            saveUi.length == 0 &&
            apiLoaded && (
              <div className="mt-3">
                <h3 className="no-project">No Users Found !</h3>
              </div>
            )
          )}
        </div>
            <div className="next-page-icons">
              <ul className="next-page-list">
                {renderPageNumbers}
              </ul>
            </div>
      </div>
    </>
  );
};

export default UserList;
