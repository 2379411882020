import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
 import UserCard from "./common/UserCard";
import "./TopMenu.css";
 
const TopMenu = () => {
  let navigate = useNavigate();

  const [CardVisible, setCardVisible] = useState(false);
  
  const userDataInfo = localStorage.getItem("UserInfo");
  const userDetails = JSON.parse(userDataInfo);
  const nameArray = userDetails?.firstName + userDetails?.surname;
  let firstNameArray=userDetails?.firstName;
  let lastNameArray=userDetails?.surname;
  if(nameArray !==undefined){
    firstNameArray = firstNameArray[0];
    lastNameArray = lastNameArray[0] || ' ';
  }

  const getInitials = () => {
    return `${firstNameArray[0]}${lastNameArray[0]}`.toUpperCase();
  };

  const handleEmail = () => {
    const userEmail = userDetails?.email
      ? userDetails.email
      : "taylor.blitz@blitzpermits.ai";
    return `${userEmail}`;
  };

  const handleLogout=()=>{
  localStorage.clear();
  sessionStorage.clear();
  navigate("/");
  window.location.reload();
  }

  const handleRedirect=()=>{
    const userData = localStorage.getItem("UserInfo");
    const userInfo = JSON.parse(userData);
    if (userInfo?.userType === "admin") {
      navigate("/dashboard");
    }
    else{
      navigate("/user-home");
    }
  }

  const handleUserProfile = () => {
    setCardVisible(true);
  };


  
  return (
    <div>
      <div>
        <nav className="nav-bar">
            <div style={{"cursor":"pointer"}} onClick={handleRedirect}>
              <img
                src={require("../../Assets/Images/blitzpermits_logo_light.png")}
                alt="logo"
                className="main-logo"
              />
            </div>
          {/* <div className="middle-col">
            {userDetails?.userType === "admin" ? (
              <>
                {" "}
                <div className="tabs-flex">
                  <Link to="/dashboard">
                    <div
                      className={
                        tabValue == "dashboard" && addClass == "flex-item-new"
                          ? "flex-item-new"
                          : "flex-item"
                      }
                      onClick={(e) => handleTabColor(e, "dashboard")}
                    >
                      Dashboard
                    </div>
                  </Link>
                  <Link to="/user-list">
                    {" "}
                    <div
                      className={
                        tabValue == "manage_users" &&
                        addClass == "flex-item-new"
                          ? "flex-item-new"
                          : "flex-item"
                      }
                      onClick={(e) => handleTabColor(e, "manage_users")}
                    >
                      Manage Users
                    </div>{" "}
                  </Link>
                  <Link to="/invoice">
                    {" "}
                    <div
                      className={
                        tabValue == "invoice" && addClass == "flex-item-new"
                          ? "flex-item-new"
                          : "flex-item"
                      }
                      onClick={(e) => handleTabColor(e, "invoice")}
                    >
                      Invoice
                    </div>
                  </Link>
                </div>
              </>
            ) : (
              ""
            )}
          </div> */}
            <div className="col-3">
  
              <div style={{cursor:"pointer"}} onClick={handleUserProfile} className={`bg-yellow ${
                    CardVisible ? "clicked" : ""
                  }`}>
                    {getInitials()}
              </div>
                {CardVisible && (
                  <UserCard
                    setCardVisible={setCardVisible}
                    getInitials={getInitials}
                    handleEmail={handleEmail}
                    handleLogout={handleLogout}
                  />
                )}
            </div>
        </nav>
      </div>
    </div>
  );
};
 
export default TopMenu;