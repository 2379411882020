import React, { useEffect, useRef, useState } from 'react';
import './TrainingManual.css';
import OpenSignFaq from "../../../Assets/Images/OpenSignFAQ.png";
import CloseSignFaq from "../../../Assets/Images/CloseSignFAQ.png";

const Accordion = ({ items, onLinkClick }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const accordionRef = useRef(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    if (accordionRef.current) {
      // Attach click events to links
      const links = accordionRef.current.querySelectorAll('a[data-video]');
      links.forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          const videoSrc = link.getAttribute('data-video');
          if (videoSrc && onLinkClick) {
            onLinkClick(videoSrc);
          }
        });
      });

      return () => {
        links.forEach((link) => {
          link.removeEventListener('click', () => {});
        });
      };
    }
  }, [activeIndex, onLinkClick]);

  return (
    <div className="accordion" ref={accordionRef}>
      {items.map((item, index) => (
        <div key={index} className="accordion-item">
          <div
            className="accordion-title"
            onClick={() => toggleAccordion(index)}
          >
            {item.title}
            <span>
              {activeIndex === index ? (
                <img src={CloseSignFaq} alt="Close" />
              ) : (
                <img src={OpenSignFaq} alt="Open" />
              )}
            </span>
          </div>
          {activeIndex === index && (
            <div
              className="accordion-content"
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
