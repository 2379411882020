import React,{useState,useEffect} from "react";
import "../UserHome/UserHome.css";
import DCardIcon from "../../../Assets/Images/DCardLogo.png";
import activeCircle from "../../../Assets/Images/activeCircle.png";
import Deteroit from "../../../Assets/Images/deteroit.png";
import ProgressBar from "../../../Assets/Images/progress.png";
import Compliance from "../../../Assets/Images/compliance.png";
import NonCompliance from "../../../Assets/Images/noncompliance.png";
import NoInformation from "../../../Assets/Images/noinformation.png";
// import GraphTime from "../../../Assets/Images/graph-time.png";
// import { BarChart } from '@mui/x-charts/BarChart';
import memberName from "../../../Assets/Images/memberName.png";
import active from "../../../Assets/Images/active.png";
import inactive from "../../../Assets/Images/inactive.png";
// import projectTypesMaps from "../../../Assets/Images/Project Types-Graph.png";
// import ThunderImage from "../../../Assets/Images/feedback_Thunder.png"
import { useDispatch } from "react-redux";
import {
  getClient, getReportChecksSummary, getReportCountSummary, getReportByUser, GetReportAverageTime, GetReportTime
} from "../../../Storage/Redux/projectSlice";
function UserReports() {
  const [selectedOption, setSelectedOption] = useState("Last 24 hours");
  const [clientName, setClientName] = useState('');
  const [activeCardId, setActiveCardId] = useState(2);
  const [reportChecksSummary, setReportChecksSummary] = useState({
    complianceChecks: 0,
    nonComplianceChecks: 0,
    noInformationChecks: 0,
  });
  const [reportCountSummary, setReportCountSummary] = useState({
    totalProjects: 0,
    completedProjects: 0,
    rejectedProjects: 0,
    resubmittedProjects: 0,
    projectsInProgress: 0
  });
  const [reportTime,setReportTime]= useState({
    projectName:"",
    fileSize: 0,
    blitzTime: 0,
    estimatedManualTime: 0,
    timeSaved: 0,
    percentTimeSaved: 0

  })
  const [reportAverageTime,setReportAverageTime]=useState("");
  const [reportByUser, setReportByUser] = useState([]);
  const dispatch = useDispatch();
  const userDataInfo = localStorage.getItem("UserInfo");
  const userDetails = JSON.parse(userDataInfo);
  const clientId = userDetails?.clientId || "";
  const memberId = userDetails?.id || "";
  useEffect(() => {
    dispatch(getClient(memberId)).unwrap()
      .then(response => {
        const clientData = response.resultObj[0];
        if (clientData && clientData.clientName) {
          setClientName(clientData.clientName);
          console.log(clientName)
        }
      })
      .catch(error => {
        console.error('Error fetching client:', error);
      });
  }, [dispatch, memberId]);

  const fetchReportCheckSummary = (timeFrame) => {
    dispatch(getReportChecksSummary({ clientId, timeFrame }))
      .unwrap()
      .then((response) => {
        setReportChecksSummary(response);
      })
      .catch((error) => {
        console.error("Error fetching report checks summary:", error);
      });
  };

  const fetchReportCountSummary = (timeFrame) => {
    dispatch(getReportCountSummary({ clientId, timeFrame }))
      .unwrap()
      .then((response) => {
        setReportCountSummary(response);
      })
      .catch((error) => {
        console.error("Error fetching report checks summary:", error);
      });
  };

  const fetchReportByUser = (timeFrame) => {
    dispatch(getReportByUser({ clientId, timeFrame }))
      .unwrap()
      .then((response) => {
        setReportByUser(response);
      })
      .catch((error) => {
        console.error("Error fetching report checks summary:", error);
      });
  };

  const fetchAverageReportTime = (timeFrame) => {
    dispatch(GetReportAverageTime({ clientId, timeFrame }))
      .unwrap()
      .then((response) => {
        setReportAverageTime(response);
      })
      .catch((error) => {
        console.error("Error fetching report checks summary:", error);
      });
  };

  const fetchReportTime = (timeFrame) => {
    dispatch(GetReportTime({ clientId, timeFrame }))
      .unwrap()
      .then((response) => {
        setReportTime(response);
      })
      .catch((error) => {
        console.error("Error fetching report checks summary:", error);
      });
  };

  useEffect(() => {
    fetchReportCheckSummary(selectedOption);
    fetchReportCountSummary(selectedOption);
    fetchReportByUser(selectedOption);
    fetchAverageReportTime(selectedOption);
    fetchReportTime(selectedOption);
    const intervalId = setInterval(() => {
      fetchReportCheckSummary(selectedOption);
      fetchReportCountSummary(selectedOption);
      fetchReportByUser(selectedOption);
      fetchAverageReportTime(selectedOption);
      fetchReportTime(selectedOption);
    }, 60000);
    return () => clearInterval(intervalId);
  }, [selectedOption, clientId]);

  const handleCardClick = (id) => {
    setActiveCardId(id);
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const totalCompletedProjects = (Array.isArray(reportByUser) ? reportByUser : Object.values(reportByUser)).reduce(
    (total, user) => total + (user.completedProjects || 0),
    0
  );

  const isArray = Array.isArray(reportTime);
  
  const totalTimeSaved = isArray 
    ? reportTime.reduce((total, item) => total + (item.timeSaved || 0), 0)
    : 0;
  
  const averageTimeSaved = isArray && reportTime.length > 0 
    ? (totalTimeSaved / reportTime.length).toFixed(2)
    : 0;

  //   const totalBlitzTime = isArray 
  //   ? reportTime.reduce((total, item) => total + (item.blitzTime || 0), 0)
  //   : 0;
  
  // const averageBlitzTimeSaved = isArray && reportTime.length > 0 
  //   ? (totalBlitzTime / reportTime.length).toFixed(2)
  //   : 0;

  //   const totalManualTime = isArray 
  //   ? reportTime.reduce((total, item) => total + (item.estimatedManualTime || 0), 0)
  //   : 0;
  
  // const averageManualTimeSaved = isArray && reportTime.length > 0 
  //   ? (totalManualTime / reportTime.length).toFixed(2)
  //   : 0;

    return (

      <div className="main-div-dashboard">
       <div className="DCard-Container">
        {
            clientName == "City of Detroit" &&
            
        <div 
           className={`DCard ${activeCardId === 1 ? 'activeCard' : ''} overview-card`} 
           onClick={() => handleCardClick(1)}
         >
           {activeCardId === 1 && <img src={activeCircle} className="activeCircle" />}
           <p>Overview</p>
           <div className="DCard-inner">
             <div>
               <img src={DCardIcon} />
             </div>
             <p>Check your<br /> teams Progress</p>
           </div>
         </div>
            
        }
         
         <div 
           className={`DCard ${activeCardId === 2 ? 'activeCard' : ''} time-analytics-card`} 
           onClick={() => handleCardClick(2)}
         >
           {activeCardId === 2 && <img src={activeCircle} className="activeCircle" />}
           <p>Time Analytics</p>
           <div className="DCard-inner">
             <h3 className="DData" style={{ color: "#308895" }}>{reportAverageTime?.reportAverageTime??0}<span style={{ fontSize: "25px" }}>mins</span></h3>
             <p>Average time taken per Blitz report</p>
           </div>
         </div>
         <div 
           className={`DCard ${activeCardId === 3 ? 'activeCard' : ''} compliance-card`} 
           onClick={() => handleCardClick(3)}
         >
           {activeCardId === 3 && <img src={activeCircle} className="activeCircle" />}
           <p>Compliance Data</p>
           <div className="DCard-inner">
             <h3 className="DData" style={{ color: "#308895" }}>{reportChecksSummary?.complianceChecks??0}</h3>
             <p>Compliances<br /> found</p>
           </div>
         </div>
         <div 
           className={`DCard ${activeCardId === 4 ? 'activeCard' : ''} user-management-card`} 
           onClick={() => handleCardClick(4)}
         >
           {activeCardId === 4 && <img src={activeCircle} className="activeCircle" />}
           <p>User Management</p>
           <div className="DCard-inner">
             <h3 className="DData" style={{ color: "#308895" }}>{totalCompletedProjects}</h3>
             <p>Reports<br /> Generated</p>
           </div>
         </div>
         <div 
           className={`DCard ${activeCardId === 5 ? 'activeCard' : ''} geographical-card`} 
           onClick={() => handleCardClick(5)}
         >
           {activeCardId === 5 && <img src={activeCircle} className="activeCircle" />}
           <p style={{color:"#6A6A6A"}}>Geographical Hotspot</p>
           <div className="DCard-inner">
             <h3 className="DData" style={{ color: "#868685" }}>&nbsp;</h3>
             <p style={{color:"#6A6A6A"}}>Coming <br />Soon</p>
           </div>
         </div>
         <div 
           className={`DCard ${activeCardId === 6 ? 'activeCard' : ''} financial-metrics-card`} 
           onClick={() => handleCardClick(6)}
         >
           {activeCardId === 6 && <img src={activeCircle} className="activeCircle" />}
           <p style={{color:"#6A6A6A"}}>Financial Metrics</p>
           <div className="DCard-inner">
             <h3 className="DData" style={{ color: "#868685"}}>&nbsp;</h3>
             <p style={{color:"#6A6A6A"}}>Coming <br />Soon</p>
           </div>
         </div>
         <div 
           className={`DCard ${activeCardId === 7 ? 'activeCard' : ''} project-card`} 
           onClick={() => handleCardClick(7)}
         >
           {activeCardId === 7 && <img src={activeCircle} className="activeCircle" />}
           <p style={{color:"#6A6A6A"}}>Project Types</p>
           <div className="DCard-inner">
             <h3 className="DData" style={{ color: "#868685" }}>&nbsp;</h3>
             <p style={{color:"#6A6A6A"}}>Coming <br />Soon</p>
           </div>
         </div>
       </div>
       <div className="DData-Container">
           {activeCardId === 1 &&  clientName == "City of Detroit" && (
               <div className="OverviewCard-Content">
               <div>
                 <p className="clientName">Welcome Charles Reed,</p>
                 <p>City of Detroit, MI is a <span className="pioneer-text">Pioneer</span> of cutting edge technology</p>
               </div>
               <div className="progressBar-Parent">
                 <div>
                 <img src={ProgressBar}/>
                 </div>
                 <img src={Deteroit}/>
               </div>
               </div>
           )}
           {
             activeCardId===2 && (
             <div className="TimeAnalytics-Content-Parent">
               <div className="time-data">
                 <select id="time-filter" value={selectedOption} onChange={handleChange} className="custom-dropdown">
                   <option value="Last 24 hours">Last 24 Hours</option>
                   <option value="Last 7 days">Last 7 Days</option>
                   <option value="Last 30 days">Last 30 Days</option>
                   <option value="all">All</option>
                 </select>
                 </div>
             <div className="TimeAnalytics-Content">
                 <div>
                     <div>
                     {/* <div className="leftPurpleBorder">
                       <p>Blitz Review v. Manual Review</p>
                     </div> */}
                     <div className="graph-color-indicators-parent">
                       {/* <div className="graph-color-indicators">
                         <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                           <div style={{backgroundColor:"#CFC4F1",width:"15px",height:"15px"}}></div>
                           <p style={{marginBottom:"0px"}}>Blitz Time</p>
                         </div>
                         <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                           <div style={{backgroundColor:"#CFDC67",width:"15px",height:"15px"}}></div>
                           <p style={{marginBottom:"0px"}}> Reviewer’s Due Diligence</p>
                         </div>
                         <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                           <div style={{backgroundColor:"#F7CDD3",width:"15px",height:"15px"}}></div>
                           <p style={{marginBottom:"0px"}}>Manual Review </p>
                         </div>
                       </div> */}
                       <div className="timeSaveCard">
                             <h4>{averageTimeSaved} mins</h4>
                             <p>Average Time Saved</p>
                       </div>
                     </div>
                     </div>
                     {/* <div className="graphDiv">
                     <BarChart
                      xAxis={[{ scaleType: 'band', data: [''] }]}
                      series={[{ data: [Number(averageBlitzTimeSaved)], color: '#CFC4F1' }, { data: [Number(averageManualTimeSaved)/3], color: '#CFDC67' }, { data: [Number(averageManualTimeSaved)], color:'#F7CDD3' }]}
                      width={450}
                      height={300}
                    />
                       <p className="timeAnalyticsNote">Note: *Due Diligence: Reviewer to confirm qualitative elements of code</p>
                     </div> */}
                 </div>
                 <div>
                     <div className="leftPurpleBorder">
                       <p>Time taken for reports</p>
                     </div>
                     <div className="table-container-div">
                     <table className="styled-table">
                       <thead>
                         <tr>
                           <th>Project Name</th>
                           <th>File Size</th>
                           <th>Blitz Time</th>
                           <th>Estimated<br/>Manual Time</th>
                           <th>Time Saved</th>
                           <th>% Time Saved</th>
                         </tr>
                       </thead>
                       <tbody>
                         { reportTime.length > 0 ? (
                          reportTime.map((reportTime,index)=>(
                            <tr key={index}>
                            <td>{reportTime.projectName}</td>
                            <td>{reportTime.fileSize} mb</td>
                            <td>{reportTime.blitzTime} mins</td>
                            <td>{reportTime.estimatedManualTime} mins</td>
                            <td>{reportTime.timeSaved} mins</td>
                            <td>{reportTime.percentTimeSaved}%</td>
                          </tr>
                          ))):(
                            <>
                            
                            </>
                          )
                         }
                       </tbody>
                     </table>
                     </div>
                 </div>
               </div>
             </div>
             )
           }
           {
             activeCardId===3 && (
               <div className="complianceCard-Content-parent">
                 <div className="time-data">
                 <select id="time-filter" value={selectedOption} onChange={handleChange} className="custom-dropdown">
                   <option value="Last 24 hours">Last 24 Hours</option>
                   <option value="Last 7 days">Last 7 Days</option>
                   <option value="Last 30 days">Last 30 Days</option>
                   <option value="all">All</option>
                 </select>
                 </div>
                 <div className="complianceCard-Content">
                 <div className="summaryChecks-parent">
                     <div className="SummaryChecks">
                       <p>Summary of Checks</p>
                     </div>
                     <div className="DCompParent">
                         <div className="DCompChild">
                           <div>
                             <img src={Compliance}/>
                           </div>
                           <div>
                             <p className="comp-number">{reportChecksSummary?.complianceChecks??0}</p>
                             <p className="comp-text">Compliant</p>
                           </div>
                         </div>
                         <div className="DCompChild">
                           <div>
                             <img src={NonCompliance}/>
                           </div>
                           <div>
                             <p className="comp-number">{reportChecksSummary?.nonComplianceChecks??0}</p>
                             <p className="comp-text">Non<br/>Compliant</p>
                           </div>
                         </div>
                         <div className="DCompChild">
                           <div>
                             <img src={NoInformation}/>
                           </div>
                           <div>
                             <p className="comp-number">{reportChecksSummary?.noInformationChecks??0}</p>
                             <p className="comp-text">Missing<br/> Information</p>
                           </div>
                         </div>
                     </div>
                   </div>
                 <div className="summaryChecks2-parent">
                     <div className="SummaryChecks2">
                       <h2>{reportCountSummary?.completedProjects??0}</h2>
                       <p>Reports<br/> Generated</p>
                     </div>
                     <div className="summaryChecks2-child">
                         <div className="summaryChecks2-tile">
                           <h3>{reportCountSummary?.totalProjects??0}</h3>
                           <p>Projects<br/> Submitted</p>
                         </div>
                         <div className="summaryChecks2-tile">
                           <h3>{reportCountSummary?.completedProjects??0}</h3>
                           <p>Projects<br/> Processed</p>
                         </div>
                         <div className="summaryChecks2-tile">
                           <h3>{reportCountSummary?.projectsInProgress??0}</h3>
                           <p>Projects<br/> In Progress</p>
                         </div>
                         <div className="summaryChecks2-tile">
                           <h3>{reportCountSummary?.rejectedProjects??0}</h3>
                           <p>Projects<br/> Rejected</p>
                         </div>
                         <div className="summaryChecks2-tile">
                           <h3>{reportCountSummary?.resubmittedProjects??0}</h3>
                           <p>Projects<br/> Resubmitted</p>
                         </div>
                     </div>
                 </div>
                 <div>
                     <div className="topcompliance">
                       <p>Top 3 Compliances</p>
                     </div>
                 </div>
                 <div>
                     <div className="topcompliance">
                       <p>Top 3 Non-Compliances</p>
                     </div>
                 </div>
                 </div>
                 
               </div>
             )
           }
           {
             activeCardId===4 && (
               <div className="userManagement-Content-parent">
                 <div className="time-data">
                 <select id="time-filter" value={selectedOption} onChange={handleChange} className="custom-dropdown">
                   <option value="Last 24 hours">Last 24 Hours</option>
                   <option value="Last 7 days">Last 7 Days</option>
                   <option value="Last 30 days">Last 30 Days</option>
                   <option value="all">All</option>
                 </select>
                 </div>
                 <div className="userManagement-Content">
                     <div className="leftOrangeBorder">
                       <p>Leaderboard</p>
                     </div>
                     <div className="table-container-div">
                     <table className="styled-table2">
                       <thead>
                         <tr>
                           <th>Member Name</th>
                           <th>Status</th>
                           <th>Project<br/> Submitted</th>
                           <th>Report<br/> Generated</th>
                           <th>Project<br/> In Progress</th>
                           <th>Project<br/> Rejected</th>
                         </tr>
                       </thead>
                       <tbody>
                       {reportByUser.length> 0 ? 
                       
                       (reportByUser.map((user, index) => (
                          <tr key={index}>
                            <td>
                              <img src={memberName} alt="Member" /> &nbsp; {user.userName}
                            </td>
                            <td style={{ color: user.activationStatus === "Active" ? "#543CA1" : "#889395" }}>
                              <img
                                src={user.activationStatus === "Active" ? active : inactive}
                                alt="Status"
                              />
                              &nbsp; {user.activationStatus}
                            </td>
                            <td>{user.totalProjects}</td>
                            <td>{user.completedProjects}</td>
                            <td>{user.projectsInProgress}</td>
                            <td>{user.rejectedProjects}</td>
                          </tr>
                        ))):
                        (
                          <>
                          
                          </>
                        )}
                       </tbody>
                     </table>
                     </div>
                 </div>  
               </div>
             )
           }
          
           {
             activeCardId === 5 && (
            //   <div className="geographicalData-Content-parent">
            //  <div className="time-data">
            //      <select id="time-filter" value={selectedOption} onChange={handleChange} className="custom-dropdown">
            //        <option value="Last 24 hours">Last 24 Hours</option>
                  //  <option value="Last 7 days">Last 7 Days</option>
                  //  <option value="Last 30 days">Last 30 Days</option>
                  //  <option value="all">All</option>
            //      </select>
            //      </div>
            //   <div className="geographicalData-Content">
            //     <div className="geo-money-stat-parent">
            //       <div className="geo-money-stat">
            //         <h2>$xx,xxx</h2>
            //         <p>Saved</p>
            //       </div>
            //       <div>
            //         <p><i>Note: Estimated hourly pay $ 30</i></p>
            //       </div>
            //     </div>
            //     <div className="geog-card-parent">
            //       <div className="geog-card-child">
            //           <div className="geog-card">
            //             <h3>410%</h3>
            //             <p>faster permit<br/> issuance</p>
            //           </div>
            //           <div className="geog-card">
            //             <h3>3.5X</h3>
            //             <p>increased reviewer<br/> productivity</p>
            //           </div>
            //       </div>
            //       <div className="geog-card-child">
            //           <div className="geog-card">
            //             <h3>83%</h3>
            //             <p>reduced manual<br/> review time</p>
            //           </div>
            //           <div className="geog-card">
            //             <h3>168%</h3>
            //             <p>uptick in annual<br/> increased in property<br/> tax revenue</p>
            //           </div>
            //       </div>
            //       <div className="geog-card-child">
            //           <div className="geog-card">
            //             <h3>8%</h3>
            //             <p>reduction in cost <br/>of new real estate <br/>for citizens</p>
            //           </div>
            //           <div className="thunder-image-cntr">
            //             <img src={ThunderImage} height={60}/>
            //           </div>
            //       </div>
            //     </div>
            //   </div>
            //  </div>
            <div>
              coming soon...
            </div>
           )}
           {
             activeCardId === 6 && (
            <div>
              coming soon...
            </div>
           )}
            {
             activeCardId===7 && (
              // <div className="projectTypes-Content-parent">
              //   <div className="time-data">
              //    <select id="time-filter" value={selectedOption} onChange={handleChange} className="custom-dropdown">
              //      <option value="Last 24 hours">Last 24 Hours</option>
              //      <option value="this week">This Week</option>
              //      <option value="this month">This Month</option>
              //      <option value="this year">This Year</option>
              //      <option value="all">All</option>
              //    </select>
              //    </div>
              //    <div className="projectTypes-Content">
              //     <div className="projectTypes-flex">
              //       <div>
              //         <div className="projectTypes-number">
              //           <h2>00</h2>
              //           <p>abcde</p>
              //         </div>
              //         <div className="projectTypes-number">
              //           <h2>00</h2>
              //           <p>abcde</p>
              //         </div>
              //       </div>
              //       <div>
              //         <div className="projectTypes-number">
              //           <h2>00</h2>
              //           <p>abcde</p>
              //         </div>
              //         <div className="projectTypes-number">
              //           <h2>00</h2>
              //           <p>abcde</p>
              //         </div>
              //       </div>
              //     </div>
              //       <div className="projectTypes-map">
              //       <img src={projectTypesMaps} height={400}/>
              //       </div>
              //     </div>
              //  </div>
              <div>
                coming soon...
              </div>
             )
           }
       </div>
     </div>

    );
 
}
export default UserReports;