import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LoginDataService from "../../Services/LoginService";

const initialState = [];

export const confirmMail = createAsyncThunk(
  "login/confirmEmail",
  async (tokenValue) => {
    const res = await LoginDataService.confirmEmail(tokenValue);
    return res;
  }
);

export const login = createAsyncThunk(
  "login/login", async (loginData) => {
    const res =  await LoginDataService.login(loginData);
    return res;
});

export const verifyCode = createAsyncThunk(
  "login/verifyCode",
  async (codeData) => {
    const res = await LoginDataService.verifyCode(codeData);
    return res;
  }
);

export const forgotPassword = createAsyncThunk(
  "login/forgotPassword",
  async (forgotData) => {
    const res = await LoginDataService.forgotPassword(forgotData);
    return res.data;
  }
);

export const resetPassword = createAsyncThunk(
  "login/resetPassword",
  async (resetData) => {
    const res = await LoginDataService.resetPassword(resetData);
    return res.data;
  }
);

export const saveReport = createAsyncThunk(
  "login/createProject",
  async (resetData) => {
    const res = await LoginDataService.saveReport(resetData);
    return res.data;
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
    [login.pending]: (state, action) => {
      console.log(action, "pending", state);
    },
    [confirmMail.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
    [saveReport.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
  },
});

const { reducer } = loginSlice;
export default reducer;
