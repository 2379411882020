import React, { useState } from 'react';
import "./FeedbackForm.css";
import X from "../../../Assets/Images/x.png";
import { submitFeedback } from '../../../Storage/Redux/feedbackFormSlice';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import FeedBackThunder from '../../../Assets/Images/feedback_Thunder.png';
import ThanksForFeedBack from '../../../Assets/Images/ThankyouFeedBack.png';
import { Link} from "react-router-dom";

function FeedbackForm({ setShowFeedbackForm }) {
  const [satisfaction, setSatisfaction] = useState('');
  const [reviewTime, setReviewTime] = useState('');
  const [confidence, setConfidence] = useState('');
  const [saveTime, setSaveTime] = useState('');
  const [additionalInputs, setAdditionalInputs] = useState('');
  const [errors, setErrors] = useState({});
  const [thankyouFeedback,setThankyouFeedback]=useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const userData = localStorage.getItem("UserInfo");
  const userInfo = JSON.parse(userData);
  const userId = userInfo?.id;

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const newErrors = {};
    if (!satisfaction) newErrors.satisfaction = 'Select your satisfaction level';
    if (!reviewTime) newErrors.reviewTime = 'Enter the average time';
    if (!confidence) newErrors.confidence = 'Select your confidence level';
    if (!saveTime) newErrors.saveTime = 'Select whether it helps to save time and money';
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    setShowLoader(true);
  
    setTimeout(async () => {
      const formData = {
        userId: `${userId}`,
        feedbacks: [
          {
            feedbackQuestion: 'How satisfied are you with the Blitz Compliance Report?* (5 being extremely satisfied)',
            feedbackAnswer: satisfaction,
          },
          {
            feedbackQuestion: "How much (estimated) time would it take for you to review this project without Blitz Permits?*",
            feedbackAnswer: reviewTime,
          },
          {
            feedbackQuestion: "How helpful is the Proof of Concept of Blitz Permits for your work?* (5 being extremely helpful)",
            feedbackAnswer: confidence,
          },
          {
            feedbackQuestion: 'Do you think Blitz Permits has the potential to save time and money for your citizens?*',
            feedbackAnswer: saveTime,
          },
          {
            feedbackQuestion: 'Please share your feedback of the product, if any',
            feedbackAnswer: additionalInputs || 'No',
          },
        ],
      };
  
      try {
        await dispatch(submitFeedback(formData)).unwrap();
        setShowLoader(false);
        setThankyouFeedback(true);
        setTimeout(()=>{
          setShowFeedbackForm(false);
        },3000)
      } catch (err) {
        console.error('Error submitting feedback:', err);
        setShowLoader(false);
      }
    }, 2000);
  };

  const closeFeedbackForm = () => {
    setShowFeedbackForm(false);
  };

  return (
    <form onSubmit={handleSubmit} >
      {
        thankyouFeedback==true? (
          <div className='formBG' style={{display:"flex",alignItems:"center"}}>
            <img src={ThanksForFeedBack} alt='FeedBackSubmitted' className='ThanksFeedbackImage'/>
            <div>
               <Link to='/user-home'>
               <button type="submit" className='FeedBack-button' style={{ color: "#543CA1" }}>Back to Home</button>
               </Link>
            </div>
          </div>
        ): (
          <div className='formBG'>
            <div className='feedbackCloseIconParent'>
              <img src={X} onClick={closeFeedbackForm} className='feedbackCloseIcon' />
            </div>

            <div className='feedbackFlexDiv'>
              <div className='QuesStrip'></div>
              <label>How satisfied are you with the Blitz Compliance Report?*<br/><span className='StarFeedback'>(5 being extremely satisfied)</span></label>
              <div className='satisfactionDiv'>
              {[1, 2, 3, 4, 5].map((num) => (
                  <label key={num} className='satisfactionDivChild'>
                    <input
                      type="radio"
                      value={num}
                      checked={satisfaction === String(num)}
                      onChange={(e) => setSatisfaction(e.target.value)}
                    />
                    {num}
                  </label>
                ))}
              </div>
              {errors.satisfaction && <p className="feedbackError">{errors.satisfaction}</p>}
            </div>

            <div className='feedbackFlexDiv'>
            <div className='QuesStrip'></div>
              <label>How much (estimated) time would it take for you to review this <br/> project without Blitz Permits?*</label>
              <input
                type="text"
                className='input-style'
                style={{backgroundColor:"#F8F8F8", outline: "none", width:"70%", border:"none"}}
                value={reviewTime}
                onChange={(e) => setReviewTime(e.target.value)}
                placeholder="minutes"
              />
              {errors.reviewTime && <p className="feedbackError">{errors.reviewTime}</p>}
            </div>

            <div className='feedbackFlexDiv'>
            <div className='QuesStrip'></div>
              <label>How helpful is the Proof of Concept of Blitz Permits for your work?*<br/><span className='StarFeedback'>(5 being extremely helpful)</span></label>
              <div className='satisfactionDiv'>
              {[1, 2, 3, 4, 5].map((num) => (
                  <label key={num} className='satisfactionDivChild'>
                    <input
                      type="radio"
                      value={num}
                      checked={confidence === String(num)}
                      onChange={(e) => setConfidence(e.target.value)}
                    />
                    {num}
                  </label>
                ))}
              </div>
              {errors.confidence && <p className="feedbackError">{errors.confidence}</p>}
            </div>

            <div className='feedbackFlexDiv'>
            <div className='QuesStrip'></div>
              <label>Do you think Blitz Permits has the potential to save time and money<br/> for your citizens?*</label>
              <div className='satisfactionDiv'>
                <label className='satisfactionDivChild'>
                  <input
                    type="radio"
                    value="Yes"
                    checked={saveTime === "Yes"}
                    onChange={(e) => setSaveTime(e.target.value)}
                  />
                  Yes
                </label>
                <label className='satisfactionDivChild'>
                  <input
                    type="radio"
                    value="No"
                    checked={saveTime === "No"}
                    onChange={(e) => setSaveTime(e.target.value)}
                  />
                  No
                </label>
              </div>
              {errors.saveTime && <p className="feedbackError">{errors.saveTime}</p>}
            </div>

            <div className='feedbackFlexDiv'>
            <div className='QuesStrip'></div>
              <label>Please share your feedback of the product, if any</label>
              <textarea
                value={additionalInputs}
                className='input-style'
                onChange={(e) => setAdditionalInputs(e.target.value)}
                placeholder=""
                style={{backgroundColor:"#F8F8F8",paddingTop: "10px", border:"none", height: "70px"}}
              />
            </div>
            {showLoader ? (
                    <div style={{ textAlign: "center"}}>
                      <FontAwesomeIcon
                        icon={faSpinner}
                        color="#5b72da"
                        spin
                        size="2x"
                      />
                    </div>
                  ) : (
                    ""
                  )}
            <div className='FeedbackButtonDiv'>
              <button type="submit" className='FeedBack-button' style={{ color: "#543CA1" }}>Submit Feedback</button>
              <div className='thunderDiv'>
              <img src={FeedBackThunder} className='thunderDivImage'/>
              </div>
            </div>
          </div>
        )
        }
    </form>
  );
}

export default FeedbackForm;
