import http from "../http-common";

const getUserList = (timeframe) => {
  return http.get(`/api/user?timeframe=${timeframe}`);
};

const getProducts = (userId) => {
  return http.get(`/api/admin/products/${userId}`);
};

const getAdminClients = (userId) => {
  return http.get(`/api/admin/AdminClients/${userId}`);
};

const AdminUserService = {
  getUserList,
  getProducts,
  getAdminClients
};

export default AdminUserService;
