import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { getProjectList } from "../../../Storage/Redux/projectSlice";
import { getDownloadFileData } from "../../../Storage/Redux/fileSlice";
import { getQueuePositions } from "../../../Storage/Redux/projectSlice";
import "./Zenith.css";
import { convertUTCToLocalTime } from "../../../common/DateHelper";
import Tooltip from "../../Layouts/common/Toolpit";
import Tooltip2 from "../../Layouts/common/Tooltip2";
import Tooltip3 from "../../Layouts/common/Tooltip3";
import TwosecGIF from "../../../Assets/Images/2secbar.gif";
import ThreemintGIF from "../../../Assets/Images/threeminute.gif";
import { faSort} from '@fortawesome/free-solid-svg-icons';
import FeedbackForm from "../../Layouts/common/FeedbackForm";
import iSymbol from "../../../Assets/Images/pdfwarning.png"
import CountDownTimer from "../../Layouts/common/CountDownTimer"

const Zenith = ({setCollapsed}) => {
  const navigate = useNavigate();
  const showSuccessMsg = localStorage.getItem("listMessage");
  const [projectData, setProjectData] = useState([]);
  const [allprojectList, setAllprojectList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [closeMsgBox, setCloseMsgBox] = useState(showSuccessMsg);
  const [errorMsg, setErrorMsg] = useState("Project created successfully");
  const [iconRefresh, setIconRefresh] = useState(false);
  const [tabName, setTabName] = useState("All");
  const [addClass, setAddClass] = useState("active-tab");
  const dispatch = useDispatch();
  const [apiLoaded, setApiLoaded] = useState(false);
  const userDataInfo = localStorage.getItem("UserInfo");
  const userDetails = JSON.parse(userDataInfo);
  const userId = userDetails?.id;
  const [currentPage, setCurrentPage] = useState(1);
  const [listPerPage, setListPerPage] = useState(10);
  const [saveUi, setSaveUi] = useState("");
  const [CurrentData, setCurruntdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingFast, setFastLoading] = useState(false);
  const [showFeedbackForm,setShowFeedbackForm]=useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'timeStamp', direction: 'descending' });
  const urlSearch = useLocation();
  const queryData = urlSearch.search;
  const filter = queryData.split("&filter=");
  const filterValue = filter[1];
  const pageValue = filter[0].split("?page=")[1];

  const fetchData = () => {
    const userData = localStorage.getItem("UserInfo");
    const userInfo = JSON.parse(userData);

    if (userInfo === null || undefined) {
      navigate("/");
    }

    console.log(setErrorMsg);
    // const productId = userDetails?.licenseData[0]?.productId || 99;
    const productId = 1;
    dispatch(getProjectList(`${userId}/${productId}`))
      .unwrap()
      .then((data) => {
        const reversedData = [...data].sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));
        setAllprojectList(reversedData);
        setProjectData(reversedData);
        setIconRefresh(false);

        const inProgressProjects = reversedData.filter(
          (project) => project.status === "In Progress"
        );

        let projectIds = inProgressProjects.map((project) => project.projectId);
        if (projectIds.length === 0) {
          projectIds = [0];
        }

        dispatch(getQueuePositions(projectIds))
        .unwrap()
        .then((queuePositionsData) => {
          const updatedProjects = reversedData.map((project) => {
            const queueInfo = queuePositionsData.find((q) => q.projectId === project.projectId);
            return { ...project, queuePosition: queueInfo ? queueInfo.queuePosition : '' };
            
          });
          setAllprojectList(updatedProjects);
          setProjectData(updatedProjects);
          setSaveUi(callTableBody(updatedProjects, currentPage, listPerPage, tabName));
        }).catch((error) => {
          console.log("Error fetching queue positions:", error);
        });

        let filterData = [];
        localStorage.setItem("listMessage", false);
        //apiLoaded = true;
        setApiLoaded(true);
        if (filterValue === undefined && pageValue === undefined) {
          setSaveUi(callTableBody(reversedData, currentPage, listPerPage, tabName));
        } else {
          setCurrentPage(pageValue);
 
          setTabName(filterValue.replace(/%20/g, " "));
          if (reversedData.length > 0 && filterValue == "Completed") {
            filterData = reversedData.filter((el) => el.status == filterValue);
            setSaveUi(
              callTableBody(filterData, pageValue, listPerPage, filterValue)
            );
          } else if (reversedData.length > 0 && filterValue == "In%20Progress") {
            filterData = reversedData.filter((el) => el.status == "In Progress");
            setSaveUi(
              callTableBody(filterData, pageValue, listPerPage, filterValue)
            );
          } else {
            setSaveUi(callTableBody(reversedData, pageValue, listPerPage, filterValue));
          }
        }
 
        setTimeout(() => {
          setCloseMsgBox(false);
        }, 4000);
      })

      .catch((e) => {
        console.log(e, "project list");
      });

  }
  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const handleClick = (event, tabName, currentPage) => {
    const params = {
      page: currentPage,
      filter: tabName,
    };

    const queryString = Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    const encodedUrl = `?${queryString}`;
    const decodedUrl = decodeURIComponent(encodedUrl);
    navigate(decodedUrl);

    let filterData = [];

    if (tabName == "In Progress") {
      let inProgressList = [...allprojectList];
      filterData = inProgressList.filter(
        (inpro) => inpro.status == "In Progress"
      );
    } else if (tabName == "Completed") {
      let inCompleteList = [...allprojectList];
      filterData = inCompleteList.filter(
        (inpro) => inpro.status == "Completed"
      );
    } else {
      filterData = [...allprojectList];
    }

    setCurruntdata(filterData);
    setListPerPage(10);
    setCurrentPage(event.target.id);
    setSaveUi(callTableBody(filterData, event.target.id, listPerPage, tabName));
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(CurrentData.length / listPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => {
    return (
      <li
        className={currentPage == number ? "active-list" : ""}
        key={number}
        id={number}
        onClick={(e) => handleClick(e, tabName, number)}
      >
        {number}
      </li>
    );
  });


  
  const callTableBody = (data, currentPage, listPerPage, tabName) => {

    let currentList = [];
    if (data.length < 10) {
      currentList = data;
      setCurruntdata(data);
    } else {
      console.log(tabName);
      setCurruntdata(data);
      const indexOfLastTodo = currentPage * listPerPage;
      const indexOfFirstTodo = indexOfLastTodo - listPerPage;
      currentList = data.slice(indexOfFirstTodo, indexOfLastTodo);
    }

    const renderprojectList =
      currentList &&
      currentList.map((item, key) => {
        const queuePosition = item.queuePosition || '';

        let status = "";
        if (item?.status == "In Progress") {
          status = "In Progress";
        } else if (item?.status == "Completed") {
          status = "Completed";
        } else if(item?.status == "Terminated. Input Error"){
          status = "Terminated. Input Error";
        } else if(item?.status == "Batch Mode"){
          status = "Batch Mode";
        } else {
          status = "Error";
        }

        return (
          <tbody className="table-body" key={key}>
            <tr className="table-row-tblrw">
              <td className="td-projectlist-tbl" style={{whiteSpace:"nowrap"}}>
                {currentPage == 1
                  ? key + 1
                  : (currentPage - 1) * 10 + (key + 1)}
              </td>
              <td className="td-projectlist-tbl" >{item?.projectName}</td>
              <td className="td-projectlist-tbl" style={{whiteSpace:"nowrap"}}>
                {convertUTCToLocalTime(item?.timeStamp)}
              </td>
              <td className="td-projectlist-tbl" style={{whiteSpace:"nowrap"}}>{item?.county}</td>

              <td className="td-projectlist-tbl">{item?.siteAddress}</td>

              {status == "In Progress" ? (
                <>
                  <td
                    className="td-projectlist-tbl"
                    style={{ color: "#CD820A",whiteSpace:"nowrap", display:"flex", alignItems: "baseline"}}
                  >
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      style={{ color: "#cd820a" }}
                    />{" "}
                    &nbsp; {status} &nbsp;
                  </td>
                </>
              ) : status == "Completed" ? (
                <>
                  <td
                    className="td-projectlist-tbl"
                    style={{ color: "#1e7137",whiteSpace:"nowrap"}}
                  >
                    {" "}
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "#1e7137" }}
                    />{" "}
                    &nbsp; {status}
                  </td>{" "}
                </>
              ) : status == "Terminated. Input Error" ? (
                <>
                
                  <td
                    className="td-projectlist-tbl"
                    style={{ color: "red",whiteSpace:"nowrap" }}
                  >
                    {" "}
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      style={{ color: "red" }}
                    />{" "}
                    &nbsp; {status} &nbsp;
                    <Tooltip2>
                    <img src={iSymbol} style={{cursor:"pointer"}} />
                    </Tooltip2>
                    
                    
                  </td>{" "}
                  
                </>
              ): status == "Batch Mode" ? (
                <>
                
                  <td
                    className="td-projectlist-tbl"
                    style={{ color: "orange",whiteSpace:"nowrap" }}
                  >
                    {" "}
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      style={{ color: "orange" }}
                    />{" "}
                    &nbsp; {status} &nbsp;
                    <Tooltip3>
                    <img src={iSymbol} style={{cursor:"pointer"}} />
                    </Tooltip3>
                    
                    
                  </td>{" "}
                  
                </>
              ): (
                <>
                  <td className="td-projectlist-tbl" style={{ color: "red" }}>
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      style={{ color: "#cc4c4c" }}
                    />{" "}
                    &nbsp; {status} 
                  </td>
                </>
              )}
              <td className="td-projectlist-tbl"><span style={{ padding: "20px" }}>{queuePosition}</span></td>
              <td className="td-projectlist-tbl">
                {item?.status !== "In Progress" && item?.status !== "Error" && item?.status !== "Terminated. Input Error" && item?.status !== "Batch Mode" ? (
                  <Tooltip content="Report">
                    <span
                      onClick={
                        item?.status == "In Progress" || item?.status == "Error" || item?.status == "Terminated. Input Error" || item?.status == "Batch Mode"
                          ? ""
                          : (e) =>
                              handleDownloadFile(
                                e,
                                item?.projectId,
                                item?.userId,
                                item?.projectName
                              )
                      }
                      style={{
                        cursor:
                          item?.status == "In Progress" ||
                          item?.status == "Error" || item?.status == "Terminated. Input Error" || item?.status == "Batch Mode"
                            ? "auto"
                            : "pointer",
                      }}
                    >
                      <span>
                        <img
                          src={require("../../../Assets/Images/icons/single.png")}
                          alt="single"
                          style={{
                            color:
                              item?.status == "In Progress" ||
                              item?.status == "Error" || item?.status == "Terminated. Input Error" || item?.status == "Batch Mode"
                                ? "#8f8484"
                                : "#523ca2",
                          }}
                        />
                      </span>
                    </span>
                  </Tooltip>
                ) : (
                  <span
                    onClick={
                      item?.status == "In Progress" || item?.status == "Error" || item?.status == "Terminated. Input Error" || item?.status == "Batch Mode"
                        ? ""
                        : (e) =>
                            handleDownloadFile(
                              e,
                              item?.projectId,
                              item?.userId,
                              item?.projectName
                            )
                    }
                    style={{
                      cursor:
                        item?.status == "In Progress" || item?.status == "Error" || item?.status == "Terminated. Input Error" || item?.status == "Batch Mode"
                          ? "auto"
                          : "pointer",
                    }}
                  >
                    <span>
                      <img
                        src={require("../../../Assets/Images/icons/singlefade.png")}
                        alt="singlefade"
                        style={{
                          color:
                            item?.status == "In Progress" ||
                            item?.status == "Error" || item?.status == "Terminated. Input Error"
                              ? "#8f8484"
                              : "#523ca2",
                        }}
                      />
                    </span>
                  </span>
                )}
                <span style={{ padding: "10px" }}>
                  {item?.status !== "In Progress" &&
                  item?.status !== "Error" && item?.status !== "Terminated. Input Error" && item?.status !== "Batch Mode" ? (
                    <Tooltip content="Annotated Report">
                      <span
                        onClick={
                          item?.status == "In Progress" ||
                          item?.status == "Error" || item?.status == "Terminated. Input Error" || item?.status == "Batch Mode"
                            ? ""
                            : (e) =>
                                handleDownloadFile(
                                  e,
                                  item?.projectId,
                                  item?.userId,
                                  item?.projectName,
                                  "merged"
                                )
                        }
                        style={{
                          cursor:
                            item?.status == "In Progress" ||
                            item?.status == "Error" || item?.status == "Terminated. Input Error" || item?.status == "Batch Mode"
                              ? "auto"
                              : "pointer",
                        }}
                      >
                        <span>
                          <img
                            src={require("../../../Assets/Images/icons/multiple.png")}
                            alt="multiple"
                            style={{
                              color:
                                item?.status == "In Progress" ||
                                item?.status == "Error" || item?.status == "Terminated. Input Error" || item?.status == "Batch Mode"
                                  ? "#8f8484"
                                  : "#523ca2",
                            }}
                          />
                        </span>
                      </span>
                    </Tooltip>
                  ) : (
                    <span
                      onClick={
                        item?.status == "In Progress" || item?.status == "Error" || item?.status == "Terminated. Input Error" || item?.status == "Batch Mode"
                          ? ""
                          : (e) =>
                              handleDownloadFile(
                                e,
                                item?.projectId,
                                item?.userId,
                                item?.projectName,
                                "merged"
                              )
                      }
                      style={{
                        cursor:
                          item?.status == "In Progress" ||
                          item?.status == "Error" || item?.status == "Terminated. Input Error" || item?.status == "Batch Mode"
                            ? "auto"
                            : "pointer",
                      }}
                    >
                      <span>
                        <img
                          src={require("../../../Assets/Images/icons/multiplefade.png")}
                          alt="multiplefade"
                          style={{
                            color:
                              item?.status == "In Progress" ||
                              item?.status == "Error" || item?.status == "Terminated. Input Error" || item?.status == "Batch Mode"
                                ? "#8f8484"
                                : "#523ca2",
                          }}
                        />
                      </span>
                    </span>
                  )}
                </span>
              </td>
            </tr>
          </tbody>
        );
      });

    return renderprojectList;
  };

  const handleClass = (e, tabName) => {
    const encodedUrl = "";
    navigate(encodedUrl);

    let pageNo = 1;
    if (tabName == "In Progress") {
      let inProgressList = [...allprojectList];
      let filterInprogress = inProgressList.filter(
        (inpro) => inpro.status == "In Progress"
      );

      setProjectData(filterInprogress);
      setSaveUi(callTableBody(filterInprogress, pageNo, listPerPage, tabName));
      setTabName("In Progress");

      setAddClass("active-tab");
    } else if (tabName == "Completed") {
      let inCompleteList = [...allprojectList];
      let filterComplete = inCompleteList.filter(
        (inpro) => inpro.status == "Completed"
      );

      setProjectData(filterComplete);
      setSaveUi(callTableBody(filterComplete, pageNo, listPerPage, tabName));
      setTabName("Completed");
      setAddClass("active-tab");
    } else {
      let inAllList = [...allprojectList];

      setProjectData(inAllList);

      setSaveUi(callTableBody(inAllList, pageNo, listPerPage, tabName));
      setTabName("All");
      setAddClass("active-tab");
    }
    setCurrentPage(pageNo);
  };

  const handleDownloadFile = (
    e,
    projectId,
    userId,
    projectName,
    fileType = ""
  ) => {
    
    setLoading(true);
    const fileData =
      fileType == ""
        ? `UserId=${userId}&ProjectId=${projectId}`
        : `UserId=${userId}&ProjectId=${projectId}&filetype=${fileType}`;
    dispatch(getDownloadFileData(fileData))
      .unwrap()
      .then((data) => {
        const base64Pdf = `data:application/pdf;base64,${data}`;
        const byteCharacters = atob(base64Pdf.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        setLoading(false);
        setFastLoading(true);
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const tempAnchor = document.createElement("a");
        tempAnchor.href = window.URL.createObjectURL(blob);
        tempAnchor.download = `${projectName}.pdf`;
        tempAnchor.click();
     
        
        setTimeout(()=>{
          setFastLoading(false);
          setShowFeedbackForm(true);
        },1500)

       
        window.URL.revokeObjectURL(tempAnchor.href);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  const handleCloseMsg = () => {
    setCloseMsgBox("false");
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filteredList = projectData.filter(
      (user) =>
        user.projectName.toLowerCase().includes(query.toLowerCase()) ||
        user.email.toLowerCase().includes(query.toLowerCase()) ||
        user.county.toLowerCase().includes(query.toLowerCase())
    );

    setSaveUi(callTableBody(filteredList, currentPage, listPerPage, tabName));
  };
  const handleRefresh = (e, tabName, currentPage) => {
    setIconRefresh(true);
    const params = {
      page: currentPage,
      filter: tabName,
    };
    const queryString = Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    const encodedUrl = `?${queryString}`;
    navigate(encodedUrl);
    window.location.reload();
  };
  const sortData = (projectData) => {
    
    let sortedData = [...projectData];
    let direction = sortConfig.direction === 'descending' ? 'ascending' : 'descending';
  
    sortedData.sort((a, b) => {
      const dateA = new Date(isNaN(a.timeStamp) ? a.timeStamp : a.timeStamp * 1000);
      const dateB = new Date(isNaN(b.timeStamp) ? b.timeStamp : b.timeStamp * 1000);
  
      if (dateA < dateB) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (dateA > dateB) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  
    setSortConfig({ key: 'timeStamp', direction });
    setProjectData(sortedData); // Update your state with the sorted data
    setAllprojectList(sortedData);
    setSaveUi(callTableBody(sortedData, currentPage, listPerPage, tabName));
  };

  function handleFeedbackForm(){
    setShowFeedbackForm(true);
}

const handleNewProject=()=>{
  setCollapsed(false);
}
  return (
    <>
      <div className="main-div">
      {
         showFeedbackForm && (
          <div className={showFeedbackForm ? "overlay" : ""}>
            <FeedbackForm showFeedbackForm={showFeedbackForm} setShowFeedbackForm={setShowFeedbackForm} handleFeedbackForm={handleFeedbackForm}/>
          </div>
          
         )
       }
        <div className="top-row">
          <div className="active-heading">
            <h4 className="main-head">Projects</h4>
          </div>
          
          <div className={loading ? "overlay" : ""}>
            {loading && (
              <div className="loade-new">
                <div className="loader-icon">
                  <img src={ThreemintGIF} className="UploadingGif" alt="three" />
                </div>
              </div>
            )}
          </div>
          <div className={loadingFast ? "overlay" : ""}>
            {loadingFast && (
              <div className="loade-new">
                <div className="loader-icon">
                  <img src={TwosecGIF} className="UploadingGif"  alt="twosec" />
                </div>
              </div>
            )}
          </div>
          <div>
          <CountDownTimer productId={1}/>
          </div>
        </div>

        <div className="top-2-row-prog">
          <div className="toggle-list-prog">
            <ul>
              <li
                className={
                  tabName == "All" && addClass == "active-tab" ? addClass : ""
                }
                onClick={(e) => handleClass(e, "All", currentPage)}
              >
                All
              </li>
              <li
                className={
                  tabName == "In Progress" && addClass == "active-tab"
                    ? addClass
                    : ""
                }
                onClick={(e) => handleClass(e, "In Progress", currentPage)}
              >
                In Progress
              </li>
              <li
                className={
                  tabName == "Completed" && addClass == "active-tab"
                    ? addClass
                    : ""
                }
                onClick={(e) => handleClass(e, "Completed", currentPage)}
              >
                Completed
              </li>
            </ul>
          </div>
          <div className="inner-box-user">
            <div className="search-container">
              <FontAwesomeIcon
                icon={faSearch}
                style={{ color: "#666666" }}
                className="search-icon"
              />
              <input
                className="input-box-user"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <button
              type="button"
              className="btn-project-refresh"
              onClick={(e) => handleRefresh(e, tabName, currentPage)}
            >
              <FontAwesomeIcon
                icon={faSync}
                size={"1px"}
                color={"#543CA1"}
                spin={iconRefresh}
              />
            </button>
            <button type="button" className="btn-project" onClick={handleNewProject}>
              <Link to="/new-blitz-planning-project">
                <span className="start-text">
                  {" "}
                  <span className="plus-icon">+</span> Add New Project
                </span>
              </Link>
            </button>
          </div>
        </div>

        {closeMsgBox == "true" ? (
          <>
            <div className="message-div">
              <div className="inner-div">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "#1E7137" }}
                />

                <span className="msg-text">{errorMsg}</span>
              </div>
              <div>
                <span onClick={handleCloseMsg} className="cross">
                  {" "}
                  X{" "}
                </span>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        <div className="table-div-completed">
          {saveUi && saveUi.length > 0 ? (
            <>
              <table className="table table-borderless">
                <thead>
                  <tr className="table-heading">
                    <th className="th-projectlist-tbl" style={{whiteSpace:"nowrap"}}> Project Id</th>
                    <th className="th-projectlist-tbl tbl-email" style={{whiteSpace:"nowrap"}}>
                      {" "}
                      Project Name
                    </th>
                    <th className="th-projectlist-tbl" style={{whiteSpace:"nowrap"}}>Created At
                    <button onClick={()=>sortData(projectData)}>
                    {/* <FontAwesomeIcon icon={faSort} /> */}
  
                    <FontAwesomeIcon icon={sortConfig.direction === 'ascending' ? faSort : faSort} />
                  </button>
                    </th>

                    <th className="th-projectlist-tbl" style={{whiteSpace:"nowrap"}}>County/City</th>
                    <th className="th-projectlist-tbl" style={{whiteSpace:"nowrap"}}>Site Address</th>
                    <th className="th-projectlist-tbl" style={{whiteSpace:"nowrap"}}>Status</th>
                    <th className="th-projectlist-tbl" style={{whiteSpace:"nowrap"}}>Queue Position</th>
                    <th className="th-projectlist-tbl" style={{whiteSpace:"nowrap"}}>Report</th>
                  </tr>
                </thead>
                {saveUi}
              </table>
            </>
          ) : (
            saveUi.length == 0 &&
            apiLoaded && (
              <div className="mt-3">
                <h3 className="no-project" style={{textAlign:"center"}}>No Project Found</h3>
              </div>
            )
          )}
        </div>

        <>
          <div className="next-page-icons">
            <ul className="next-page-list">
              {renderPageNumbers}
            </ul>
          </div>
        </>
      </div>
    </>
  );
};
export default Zenith;
