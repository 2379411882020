export const convertUTCToLocalTime = (utcTimeString) => {
  const utcDate = new Date(utcTimeString);
  const userOffsetMinutes = utcDate.getTimezoneOffset();
  const localDate = new Date(utcDate.getTime() - userOffsetMinutes * 60 * 1000);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  };
  const formattedLocalTime = localDate.toLocaleString("en-US", options);
  return formattedLocalTime;
};
