import React, {useEffect,useState} from "react";
import {useLocation} from "react-router-dom"
 
const ShowTopMenu=({children})=>{
 
    const location=useLocation();
    const [showNavBar,setShowNavBar]=useState(false)
    useEffect(()=>{
        if(location.pathname==='/' ||
        location.pathname==='/user-registration' ||
        location.pathname==='/register' ||
        location.pathname==='/verify-code' ||
        location.pathname==='/forgot-password' ||
        location.pathname==='/reset-password' ||
        location.pathname==='/confirm-email'
     ){
            setShowNavBar(false);
        }
        else{
            setShowNavBar(true);
        }
    },[location])
    return <div>{showNavBar && children}</div>
}
 
export default ShowTopMenu