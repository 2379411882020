import React, { useState,useEffect} from 'react';
import { useLocation } from "react-router-dom";
import './Sidebar.css';
import { Link } from "react-router-dom";
import DashboardIcon from "../../../Assets/Images/dashboardIcon.png";
import HomeIcon from "../../../Assets/Images/Home-icon-pro.png";
import AddNewUser from "../../../Assets/Images/add-new-user.png";
import ManageUsers from "../../../Assets/Images/manage-users.png";
import TrainingManual from "../../../Assets/Images/TrainingManual.png";
import DataReports from "../../../Assets/Images/DataReports.png";
import ProductIcon from "../../../Assets/Images/ProductIcon.png";
import BlitzPlanning from "../../../Assets/Images/Blitz-Planning-Logo.png";
import BlitzBuildingC from "../../../Assets/Images/Blitz-BuildingC-Logo.png";
import BlitzBuildingR from "../../../Assets/Images/Blitz-BuildingR-Logo.png";
import DropOpen from "../../../Assets/Images/DropOpen.png";
import DropClose from "../../../Assets/Images/DropClose.png";
import faBars from "../../../Assets/Images/ham-menu.png";
import BlitzEngineeringLogo from "../../../Assets/Images/Blitz-Engineering-Logo-Tile.png";
import BlitzEnviornmentalLogo from "../../../Assets/Images/Blitz-Environmental-Logo-Tile.png";
import BlitzFireMarshalLogo from "../../../Assets/Images/Blitz-Firemarshal-Logo-Tile.png";
import BlitzHealthLogo from "../../../Assets/Images/Blitz-Health-Logo-Tile.png";
import BlitzPublicWorksLogo from "../../../Assets/Images/Blitz-Publicworks-Logo-Tile.png";
import BlitzTransportationLogo from "../../../Assets/Images/Blitz-Transportation-Logo-Tile.png";
import BlitzJarvizLogo from "../../../Assets/Images/Blitz-Jarviz-Logo-Tile.png";
import BlitzHistoricalPreservationLogo from "../../../Assets/Images/BlitzHistoricPreservation Logo-14.png";
// import ShowAllProducts from "../../../Assets/Images/showMoreProducts.png";
// import ShowLessProducts from "../../../Assets/Images/showLessProducts.png";

const Sidebar = ({collapsed,setCollapsed}) => {
  const userDataInfo = localStorage.getItem("UserInfo");
  const userDetails = JSON.parse(userDataInfo);
  const licenseData = userDetails?.licenseData || [];

  const [showResourcesSubMenu, setShowResourcesSubMenu] = useState(false);
  // const [showMore, setShowMore] = useState(false);

  const handleProductOpen=()=>{
    setCollapsed(false);
  }
 
  // const handleShowMore = () => {
  //   setShowMore(!showMore);
  // };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
    if (showResourcesSubMenu) {
      setShowResourcesSubMenu(false);
    }
  };
  const location = useLocation();
   useEffect(()=>{
  
    if(location?.pathname=="/blitz-planning" || location?.pathname=="/blitz-residential-building" || location?.pathname=="/blitz-commercial-building" || location?.pathname=="/new-blitz-planning-project" || location?.pathname=="/new-blitz-commercial-building-project" || location?.pathname=="/new-blitz-residential-building-project"){
      setShowResourcesSubMenu(true);
    }
    else{
      setShowResourcesSubMenu(false);
    }

  },[location.pathname])
 
  const toggleResourcesSubMenu = () => {
    setShowResourcesSubMenu(!showResourcesSubMenu);
  };
  const isProductAccessible = (productId) => {
    return licenseData.some(item => item.productId === productId);
  };

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
      {userDetails?.userType === "admin" ? (
        <div className={`sidebar-parent ${collapsed ? 'collapsed' : ''}`}>
        <div className='sidebar-firstHalf'>
        <div className='ham-icon-main'>
        <img src={faBars} style={{ color: "#543CA1",cursor:"pointer", height: "30px" }} className='ham-icon' onClick={toggleCollapse}/>
          
        </div>
        <Link to="/dashboard" className={`sidebar-child ${location.pathname === "/dashboard" ? 'active' : ''}`}>
          <div className={`Sidebar-icon-div ${collapsed ? 'collapsed' : ''}`}>
          <div><img src={DashboardIcon} className='sidebar-icons'/></div>
          <div>Dashboard</div>
          </div>
        </Link>
        <Link to="/user-list" className={`sidebar-child ${location.pathname === "/user-list" ? 'active' : ''}`}>
          <div className={`Sidebar-icon-div ${collapsed ? 'collapsed' : ''}`}>
          <div><img src={ManageUsers} className='sidebar-icons'/></div>
          <div className={`Sidebar-Name-Wrap ${collapsed ? 'collapsed' : ''}`}>Manage Users</div>
          </div>
        </Link>
        <Link to='/user' className={`sidebar-child ${location.pathname === "/user" ? 'active' : ''}`}>
          <div className={`Sidebar-icon-div ${collapsed ? 'collapsed' : ''}`}>
          <div><img src={AddNewUser} className='sidebar-icons'/></div>
          <div className={`Sidebar-Name-Wrap ${collapsed ? 'collapsed' : ''}`}>Add New User</div>
          </div>
        </Link>
        </div>
        </div>
      ):(
      <div className={`sidebar-parent ${collapsed ? 'collapsed' : ''}`}>
        <div className='sidebar-firstHalf'>
        <div className='ham-icon-main'>
        <img src={faBars} style={{ color: "#543CA1",cursor:"pointer", height: "30px" }} className='ham-icon' onClick={toggleCollapse}/>
          
        </div>
        <Link to="/user-home" className={`sidebar-child ${location.pathname === "/user-home" ? 'active' : ''}`}>
          <div className={`Sidebar-icon-div ${collapsed ? 'collapsed' : ''}`}>
          <div><img src={HomeIcon} className='sidebar-icons'/></div>
          <div>Home</div>
          </div>
        </Link>
        <Link to="/user-reports" className={`sidebar-child ${location.pathname === "/user-reports" ? 'active' : ''}`}>
          <div className={`Sidebar-icon-div ${collapsed ? 'collapsed' : ''}`}>
          <div><img src={DataReports} className='sidebar-icons'/></div>
          <div className={`Sidebar-Name-Wrap ${collapsed ? 'collapsed' : ''}`}>Dashboard</div>
          </div>
        </Link>
        <Link to='/training-manual' className={`sidebar-child ${location.pathname === "/training-manual" ? 'active' : ''}`}>
          <div className={`Sidebar-icon-div ${collapsed ? 'collapsed' : ''}`}>
          <div><img src={TrainingManual} className='sidebar-icons'/></div>
          <div className={`Sidebar-Name-Wrap ${collapsed ? 'collapsed' : ''}`}>Training Manual</div>
          </div>
        </Link>
        </div>
        <div className='sidebar-secondHalf' onClick={handleProductOpen}>
          <div className={`sidebar-child ${showResourcesSubMenu ? 'open' : ''}`} onClick={toggleResourcesSubMenu}>
          <div className={`Sidebar-icon-div ${collapsed ? 'collapsed' : ''}`} >
          <div ><img src={ProductIcon} className='sidebar-icons'/></div>
          <div>Products  &nbsp;<img src={`${showResourcesSubMenu ? DropOpen : DropClose}`} className='down-arrow-products'/></div>
          </div>
        </div>
        {showResourcesSubMenu && (
          <div className={!collapsed ? 'sidebar-submenu' : 'sidebar-submenu-2'}>
          <div>
            <Link to="/blitz-planning"
              className={`sidebar-child-prod ${location.pathname === "/blitz-planning" || location.pathname === "/new-blitz-planning-project" ? 'active' : ''} ${isProductAccessible(1) ? '' : 'disabled'}`}
              style={{ pointerEvents: isProductAccessible(1) ? 'auto' : 'none', opacity: isProductAccessible(1) ? 1 : 0.5 }}>
              <div><img src={BlitzPlanning} className='products-image'/></div>
            </Link>
          </div>
          <div>
            <Link to="/blitz-residential-building"
              className={`sidebar-child-prod ${location.pathname === "/blitz-residential-building" || location.pathname === "/new-blitz-residential-building-project" ? 'active' : ''} ${isProductAccessible(3) ? '' : 'disabled'}`}
              style={{ pointerEvents: isProductAccessible(3) ? 'auto' : 'none', opacity: isProductAccessible(3) ? 1 : 0.5 }}>
              <div><img src={BlitzBuildingR} className='products-image'/></div>
            </Link>
          </div>
          <div>
            <Link to="/blitz-commercial-building"
              className={`sidebar-child-prod ${location.pathname === "/blitz-commercial-building" || location.pathname === "/new-blitz-commercial-building-project" ? 'active' : ''} ${isProductAccessible(2) ? '' : 'disabled'}`}
              style={{ pointerEvents: isProductAccessible(2) ? 'auto' : 'none', opacity: isProductAccessible(2) ? 1 : 0.5 }}>
              <div><img src={BlitzBuildingC} className='products-image'/></div>
            </Link>
          </div>
          {/* {!showMore && (
            <button onClick={handleShowMore} className="show-more-btn">Show All Products <img src={ShowAllProducts}/></button>
          )}
          {showMore && (
          <> */}
          <div>
            <Link to=""
              className={`sidebar-child-prod ${location.pathname === "" || location.pathname === "" ? 'active' : ''} ${isProductAccessible(4) ? '' : 'disabled'}`}
              style={{ pointerEvents: isProductAccessible(4) ? 'auto' : 'none'}}>
              <div><img src={BlitzJarvizLogo} className='products-image' style={{ backgroundColor: isProductAccessible(4) ? '#F2F9D1' : '#C8C8C8', height:"45px"}}/></div>
            </Link>
          </div>
          <div>
            <Link to=""
              className={`sidebar-child-prod ${location.pathname === "" || location.pathname === "" ? 'active' : ''} ${isProductAccessible(5) ? '' : 'disabled'}`}
              style={{ pointerEvents: isProductAccessible(5) ? 'auto' : 'none'}}>
              <div><img src={BlitzEngineeringLogo} className='products-image' style={{ backgroundColor: isProductAccessible(5) ? '#F2F9D1' : '#C8C8C8'}}/></div>
            </Link>
          </div>
          <div>
            <Link to=""
              className={`sidebar-child-prod ${location.pathname === "" || location.pathname === "" ? 'active' : ''} ${isProductAccessible(6) ? '' : 'disabled'}`}
              style={{ pointerEvents: isProductAccessible(6) ? 'auto' : 'none'}}>
              <div><img src={BlitzTransportationLogo} className='products-image' style={{ backgroundColor: isProductAccessible(6) ? '#F2F9D1' : '#C8C8C8'}}/></div>
            </Link>
          </div>
          <div>
            <Link to=""
              className={`sidebar-child-prod ${location.pathname === "" || location.pathname === "" ? 'active' : ''} ${isProductAccessible(11) ? '' : 'disabled'}`}
              style={{ pointerEvents: isProductAccessible(11) ? 'auto' : 'none'}}>
              <div><img src={BlitzHistoricalPreservationLogo} className='products-image' style={{ backgroundColor: isProductAccessible(11) ? '#F2F9D1' : '#C8C8C8'}}/></div>
            </Link>
          </div>
          <div>
            <Link to=""
              className={`sidebar-child-prod ${location.pathname === "" || location.pathname === "" ? 'active' : ''} ${isProductAccessible(7) ? '' : 'disabled'}`}
              style={{ pointerEvents: isProductAccessible(7) ? 'auto' : 'none'}}>
              <div><img src={BlitzFireMarshalLogo} className='products-image' style={{ backgroundColor: isProductAccessible(7) ? '#F2F9D1' : '#C8C8C8'}}/></div>
            </Link>
          </div>
          <div>
            <Link to=""
              className={`sidebar-child-prod ${location.pathname === "" || location.pathname === "" ? 'active' : ''} ${isProductAccessible(8) ? '' : 'disabled'}`}
              style={{ pointerEvents: isProductAccessible(8) ? 'auto' : 'none'}}>
              <div><img src={BlitzPublicWorksLogo} className='products-image' style={{ backgroundColor: isProductAccessible(8) ? '#F2F9D1' : '#C8C8C8'}}/></div>
            </Link>
          </div>
          <div>
            <Link to=""
              className={`sidebar-child-prod ${location.pathname === "" || location.pathname === "" ? 'active' : ''} ${isProductAccessible(9) ? '' : 'disabled'}`}
              style={{ pointerEvents: isProductAccessible(9) ? 'auto' : 'none'}}>
              <div><img src={BlitzEnviornmentalLogo} className='products-image' style={{ backgroundColor: isProductAccessible(9) ? '#F2F9D1' : '#C8C8C8'}}/></div>
            </Link>
          </div>
          <div>
            <Link to=""
              className={`sidebar-child-prod ${location.pathname === "" || location.pathname === "" ? 'active' : ''} ${isProductAccessible(10) ? '' : 'disabled'}`}
              style={{ pointerEvents: isProductAccessible(10) ? 'auto' : 'none'}}>
              <div><img src={BlitzHealthLogo} className='products-image' style={{ backgroundColor: isProductAccessible(10) ? '#F2F9D1' : '#C8C8C8', height:"45px"}}/></div>
            </Link>
          </div>
          
          {/* <button onClick={handleShowMore} className="show-more-btn">HIde All Products <img src={ShowLessProducts}/></button> */}
        {/* </> */}
      {/* )} */}
        </div>
        )}
          </div>
      </div>
      )}
    </div>
  );
};
 
export default Sidebar;