import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import feedbackFormService from "../../Services/FeedbackFormService";

const initialState = {
  feedback: null,
  status: 'idle',
  error: null,
};

export const submitFeedback = createAsyncThunk(
  "feedback/submitFeedback",
  async (userFeedback, { rejectWithValue }) => {
    try {
      const response = await feedbackFormService.feedBackForm(userFeedback);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const feedbackFormSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitFeedback.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitFeedback.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.feedback = action.payload;
      })
      .addCase(submitFeedback.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default feedbackFormSlice.reducer;