import React from "react";
import "./UserHome.css";
import BlitzPlanningLogo from "../../../Assets/Images/Blitz-Planning-Logo-Tile.png";
import BlitzBuildingRLogo from "../../../Assets/Images/Blitz-Building-Residential-Logo.png";
import BlitzBuildingCLogo from "../../../Assets/Images/Blitz-Building-Commercial-Logo.png";
import BlitzEngineeringLogo from "../../../Assets/Images/Blitz-Engineering-Logo-Tile.png";
import BlitzEnviornmentalLogo from "../../../Assets/Images/Blitz-Environmental-Logo-Tile.png";
import BlitzFireMarshalLogo from "../../../Assets/Images/Blitz-Firemarshal-Logo-Tile.png";
import BlitzHealthLogo from "../../../Assets/Images/Blitz-Health-Logo-Tile.png";
import BlitzPublicWorksLogo from "../../../Assets/Images/Blitz-Publicworks-Logo-Tile.png";
import BlitzTransportationLogo from "../../../Assets/Images/Blitz-Transportation-Logo-Tile.png";
import BlitzHistoricalPreservationLogo from "../../../Assets/Images/BlitzHistoricPreservation Logo-14.png";
import BlitzJarvizLogo from "../../../Assets/Images/Blitz-Jarviz-Logo-Tile.png";
import { Link } from "react-router-dom";
function UserHome() {

    const userDataInfo = localStorage.getItem("UserInfo");
    const userDetails = JSON.parse(userDataInfo);
    const licenseData = userDetails?.licenseData || [];

    const capitalizeFirstLetter=(Name)=>  Name.charAt(0).toUpperCase() + Name.slice(1);

    const isProductAccessible = (productId) => {
        return licenseData.some(item => item.productId === productId);
      };
    
    return (
        <div className="main-div">
        <div className="row-1 dashboard-main-div" style={{borderBottom:"2px solid #E6E6E6", paddingBottom:"10px"}}>
          <div className="main-head">Home</div>
        </div>
        <div className="dashboard-div">
        <div className="Greeting-message-Dashboard">
            <h2>
            <span className="username-greeting">Hello {userDetails !== null
            ?capitalizeFirstLetter(userDetails?.firstName) + " " + capitalizeFirstLetter(userDetails?.surname)
            : "Taylor Blitz"}</span>,&nbsp; Welcome to Blitz Permits
            </h2>
            <p>Please select below, for product specific analytics. Better technology for better governance.</p>
        </div>
      </div>
      <div className="tilesDic">
        <Link to="/blitz-planning" style={{ pointerEvents: isProductAccessible(1) ? 'auto' : 'none', opacity: isProductAccessible(1) ? 1 : 0.5 }}>
        <div className="productCard">
          <img src={BlitzPlanningLogo} className="ActiveProductTileImages"/>
        </div>
        </Link>
        <Link to="/blitz-residential-building" style={{ pointerEvents: isProductAccessible(3) ? 'auto' : 'none', opacity: isProductAccessible(3) ? 1 : 0.5 }}>
        <div className="productCard">
          <img src={BlitzBuildingRLogo} className="ActiveProductTileImages"/>
        </div>
        </Link>
        <Link to="/blitz-commercial-building" style={{ pointerEvents: isProductAccessible(2) ? 'auto' : 'none', opacity: isProductAccessible(2) ? 1 : 0.5 }}>
        <div className="productCard">
          <img src={BlitzBuildingCLogo} className="ActiveProductTileImages"/>
        </div>
        </Link>
      </div>
      <div className="tilesDic2">
      <div className="productCardS">
          <img src={BlitzJarvizLogo} className="productTileImages"/>
        </div>
        <div className="productCardS">
          <img src={BlitzEngineeringLogo} className="productTileImages"/>
        </div>
        <div className="productCardS">
          <img src={BlitzTransportationLogo} className="productTileImages"/>
        </div>
        <div className="productCardS">
          <img src={BlitzHistoricalPreservationLogo} className="productTileImages"/>
        </div>
      </div>
      <div className="tilesDic2">
      <div className="productCardS">
          <img src={BlitzFireMarshalLogo} className="productTileImages"/>
        </div>
      <div className="productCardS">
          <img src={BlitzPublicWorksLogo} className="productTileImages"/>
        </div>
        <div className="productCardS">
          <img src={BlitzEnviornmentalLogo} className="productTileImages"/>
        </div>
        <div className="productCardS">
          <img src={BlitzHealthLogo} className="productTileImages"/>
        </div>
      </div>
</div>
    );

}
export default UserHome;