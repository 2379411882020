import React,{useRef,useEffect} from 'react';
import "./PasswordPolicy.css";

const PasswordPolicy = ({setShowPasswordPolicy, showPasswordPolicy}) => {

    const closePolicy = () => {
        setShowPasswordPolicy(false);
    }
    const PasswordCardRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (PasswordCardRef.current && !PasswordCardRef.current.contains(event.target)) {
                setShowPasswordPolicy(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showPasswordPolicy]);

  return (
        <div className='policy-main' ref={PasswordCardRef}>
            <div className='policy-cross' onClick={closePolicy}>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                <path d="M1 12L12 1M1 1L12 12" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>
             </div>
            </div>
            <ul className='password-list'>
                <li>&bull; Atleast one uppercase letter</li>
                <li>&bull; Atleast one lowercase letter</li>
                <li>&bull; Atleast one special character</li>
                <li>&bull; Atleast one digit</li>
                <li>&bull; Atleast 10 characters in length</li>
            </ul>
        </div>
  )
}

export default PasswordPolicy