import axios from "axios";
const token="";
const apiUrl = "https://api-blitz-prod.azurewebsites.net";
export default axios.create({
  baseURL: apiUrl,
  headers: {
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
    "content-type": "multipart/form-data" ,
    "Authorization": `Bearer ${token}`,
  }
});
